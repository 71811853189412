<template>
  <div class="flex flex-wrap">
    <div class="w-full py-2">
      <div>
        <label class="uppercase font-semibold text-sm"
        >Supplier </label
        >
        <div>
          <ContactAutocomplete
              class="w-full"
              v-model="supplier"
              @contactSelected="handleSupplierSelected"
              @contactCleared="handleSupplierCleared"
              :forceSelection="true"/>
        </div>
        <div class="w-full flex">
          <ContactInfoCard :contact="supplier"></ContactInfoCard>
        </div>
      </div>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
      >Supplier Item Description <span class="text-xs">(SHOWN ON PO)</span></label
      >
      <textarea
          v-model="supplierDetails.item_description"
          class="w-full bge-input bge-input-spacing rounded"
      ></textarea>
    </div>

    <div class="flex w-full">
      <!-- Supplier Part Number -->
      <div class="w-1/2 pr-2 mb-4">
        <label class="block uppercase font-semibold text-sm mb-1">Supplier Part Number</label>
        <input
            v-model="supplierDetails.supplier_part_number"
            type="text"
            class="w-full bge-input bge-input-spacing rounded"
        />
      </div>

      <!-- Delivery Lead Time -->
      <div class="w-1/2 pl-2  mb-4">
        <label class="block uppercase font-semibold text-sm mb-1">Delivery Lead Time</label>
        <select
            v-model="supplierDetails.delivery_lead_time"
            class="w-full bge-input bge-input-spacing rounded"
        >
          <option
              v-for="time in deliveryTimes"
              :key="time.data_master_id"
              :value="time.value"
          >
            {{ time.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex w-full">
      <!-- Currency -->
      <div class="w-1/2 pr-2 mb-4">
        <div class="flex items-start space-x-4">
          <!-- Currency Dropdown -->
          <div class="w-1/2">
            <label class="block uppercase font-semibold text-sm mb-1">Currency</label>
            <select
                v-model="supplierDetails.currency"
                class="w-full bge-input bge-input-spacing rounded"
            >
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
            </select>
          </div>

          <!-- Unit Price -->
          <div class="w-1/2">
            <label class="block uppercase font-semibold text-sm mb-1">Unit Price</label>
            <input
                v-model="supplierDetails.unit_price"
                type="text"
                class="w-full bge-input bge-input-spacing rounded"
                placeholder="Enter Price"
            />
          </div>
        </div>
      </div>


      <!-- Nominal Code -->
      <div class="w-1/2 pl-2 mb-4">
        <label class="block uppercase font-semibold text-sm mb-1">Nominal Code</label>
        <select
            v-model="supplierDetails.purchase_nominal_code"
            class="w-full bge-input bge-input-spacing rounded"
        >
          <option
              v-for="code in nominalCodes"
              :key="code.data_master_id"
              :value="code.value"
          >
            {{ code.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex w-full">
      <div class="w-1/2 pr-2 mb-4">
        <label class="block uppercase font-semibold text-sm mb-1">Set as Default</label>
        <div class="flex items-center">
            <InputSwitch v-model="supplierDetails.is_default" />
        </div>
      </div>
    </div>

    <div class="flex w-full justify-end mt-4">
      <AppButton color="green" label="Save Supplier" @click="saveSupplierDetails" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="save" class="mr-2" />
        </template>
      </AppButton>
    </div>


  </div>
</template>

<script>
import ContactInfoCard from "@/components/Contacts/InfoCard.vue";
import ContactAutocomplete from "@/components/AutoComplete/ContactAutoComplete.vue";
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "AddSupplier",
  mixins: [notificationMixin],
  components: {Icon, AppButton, ContactAutocomplete, ContactInfoCard},
  props: {
    supplierDetailsProp: {
      type: Object,
      required: false, // Allows for creating a new supplier if not provided
      default: () => null,
    },
    product_id: {
      type: String,
      required: true, // This prop is mandatory
    },
  },
  data() {
    return {
      supplierDetails: {
        supplier: null,
        item_description: "",
        part_number: "",
        delivery_lead_time: "",
        unit_price: "",
        currency: "GBP",
        nominal_code: "",

      },

      deliveryTimes: [],
      nominalCodes: [],
      supplier: null,
    };
  },
  async mounted() {
    // If the prop is passed, assign its value to local state
    if (this.supplierDetailsProp) {
      this.supplierDetails = { ...this.supplierDetailsProp };

      // Extract supplier info, addresses, and people
      const supplierInfo = this.supplierDetailsProp.supplier_info;
      const addresses = supplierInfo && supplierInfo.addresses;
      const peopleList = supplierInfo && supplierInfo.people;

      // Determine the address
      let addressToAssign = null;
      if (addresses && Array.isArray(addresses)) {
        const defaultAddress = addresses.find(addr => addr.is_default);
        addressToAssign = defaultAddress || addresses[0];
      }

      // Determine the person
      let personToAssign = null;
      if (peopleList && Array.isArray(peopleList)) {
        const defaultPerson = peopleList.find(person => person.is_default);
        personToAssign = defaultPerson || peopleList[0];
      }

      // Assign supplier with the address and person included
      this.supplier = {
        ...supplierInfo,
        address: addressToAssign, // Add the selected address to the supplier object
        people: personToAssign,  // Add the selected person to the supplier object
      };
    }

debugger;
    await Promise.all([this.fetchDeliveryTimes(), this.fetchNominalCodes()]);
  },
  methods: {
    async fetchDeliveryTimes() {
      try {
        const response = await this.DatamasterService.getDataMasters(
            "?type=DeliveryLeadTime&is_active=true&noPaging=true&orderBy=order&order=1"
        );
        this.deliveryTimes = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching delivery times:", error);
      }
    },
    async fetchNominalCodes() {
      try {
        const response = await this.DatamasterService.getDataMasters(
            "?type=PurchaseNominalCode&is_active=true&noPaging=true&orderBy=order&order=1"
        );
        this.nominalCodes = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching nominal codes:", error);
      }
    },
    mapDropdownOptions(response) {
      return response.map((item) => ({
        data_master_id: item.data_master_id,
        name: item.name,
        value: item.value || "",
      }));
    },
    async saveSupplierDetails() {
      try {
        // Check if supplier exists and assign contact_id to supplier_id
        if (this.supplier && this.supplier.contact_id) {
          this.supplierDetails.supplier_id = this.supplier.contact_id;
        } else if (!this.supplierDetails.supplier_id) {
          this.notifyError("Please select a valid supplier before saving.");
          return;
        }

        if (this.supplierDetails.supplier_detail_id) {
          // Update existing supplier
          await this.ProductServiceV2.updateSupplierDetail(
              this.product_id,
              this.supplierDetails.supplier_detail_id,
              this.supplierDetails
          );
          this.notifySuccess("Supplier updated successfully.");
        } else {
          // Create new supplier
          const createdSupplier = await this.ProductServiceV2.createSupplierDetail(
              this.product_id,
              this.supplierDetails
          );
          this.supplierDetails.supplier_detail_id =
              createdSupplier.supplier_detail_id; // Save the ID of the newly created supplier
          this.notifySuccess("Supplier created successfully.");
        }

        // Notify parent component to refresh data
        this.$emit("complete");
      } catch (error) {
        console.error("Error saving supplier details:", error);
        this.notifyError("An error occurred while saving supplier details.");
      }
    },


    handleSupplierSelected(selectedCustomer) {
      // Logic to handle supplier selection
    },

    handleSupplierCleared() {
      // Logic to handle supplier clearing
    },
  },
};
</script>

