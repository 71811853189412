import axios from "axios";

const productUrl = "/v2/api/products";

class ProductServiceV2 {
    // Product operations
    static searchProducts(urlParams) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}${urlParams}`;
                const result = await axios.get(url);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getProductById(productId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}`;
                const result = await axios.get(url);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getProductByCode(productCode) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/by-code/${productCode}`;
                const result = await axios.get(url);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createProduct(productData) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.post(productUrl, productData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateProduct(productId, updatedProductData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}`;
                await axios.put(url, updatedProductData);
                resolve(`Product with ID ${productId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static verifyProduct(productId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/verify`;
                await axios.put(url);
                resolve(`Product with ID ${productId} verified successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static archiveProduct(productId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/archive`;
                await axios.put(url);
                resolve(`Product with ID ${productId} archived successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Line Item operations
    static createLineItem(productId, lineItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/line-items`;
                const result = await axios.post(url, lineItemData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateLineItem(productId, lineItemId, updatedLineItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/line-items/${lineItemId}`;
                await axios.put(url, updatedLineItemData);
                resolve(`Line item with ID ${lineItemId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteLineItem(productId, lineItemId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/line-items/${lineItemId}`;
                await axios.delete(url);
                resolve(`Line item with ID ${lineItemId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Price Band operations
    static createPriceBand(productId, priceBandData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/price-bands`;
                const result = await axios.post(url, priceBandData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updatePriceBand(productId, priceBandId, updatedPriceBandData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/price-bands/${priceBandId}`;
                await axios.put(url, updatedPriceBandData);
                resolve(`Price band with ID ${priceBandId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deletePriceBand(productId, priceBandId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/price-bands/${priceBandId}`;
                await axios.delete(url);
                resolve(`Price band with ID ${priceBandId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Document operations
    static uploadDocument(productId, documentData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/documents`;
                const result = await axios.post(url, documentData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteDocument(productId, documentId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/documents/${documentId}`;
                await axios.delete(url);
                resolve(`Document with ID ${documentId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Service Template operations
    static createServiceTemplate(productId, serviceTemplateData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/service-templates`;
                const result = await axios.post(url, serviceTemplateData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateServiceTemplate(productId, templateId, updatedTemplateData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/service-templates/${templateId}`;
                await axios.put(url, updatedTemplateData);
                resolve(`Service template with ID ${templateId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteServiceTemplate(productId, templateId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/service-templates/${templateId}`;
                await axios.delete(url);
                resolve(`Service template with ID ${templateId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Certificate Type operations
    static createCertificateType(productId, certificateTypeData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/certificate-types`;
                const result = await axios.post(url, certificateTypeData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateCertificateType(productId, certificateTypeId, updatedCertificateTypeData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/certificate-types/${certificateTypeId}`;
                await axios.put(url, updatedCertificateTypeData);
                resolve(`Certificate type with ID ${certificateTypeId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteCertificateType(productId, certificateTypeId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/certificate-types/${certificateTypeId}`;
                await axios.delete(url);
                resolve(`Certificate type with ID ${certificateTypeId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Shipping Details operations
    static createShippingDetail(productId, shippingDetailData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/shipping-details`;
                const result = await axios.post(url, shippingDetailData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateShippingDetail(productId, shippingDetailId, updatedShippingDetailData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/shipping-details/${shippingDetailId}`;
                await axios.put(url, updatedShippingDetailData);
                resolve(`Shipping detail with ID ${shippingDetailId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteShippingDetail(productId, shippingDetailId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/shipping-details/${shippingDetailId}`;
                await axios.delete(url);
                resolve(`Shipping detail with ID ${shippingDetailId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Supplier Details operations
    static createSupplierDetail(productId, supplierDetailData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/supplier-details`;
                const result = await axios.post(url, supplierDetailData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateSupplierDetail(productId, supplierDetailId, updatedSupplierDetailData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/supplier-details/${supplierDetailId}`;
                await axios.put(url, updatedSupplierDetailData);
                resolve(`Supplier detail with ID ${supplierDetailId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteSupplierDetail(productId, supplierDetailId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/supplier-details/${supplierDetailId}`;
                await axios.delete(url);
                resolve(`Supplier detail with ID ${supplierDetailId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Bill of Material operations
    static createBillOfMaterial(productId, bomData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/bill-of-materials`;
                const result = await axios.post(url, bomData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateBillOfMaterial(productId, bomId, updatedBomData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/bill-of-materials/${bomId}`;
                await axios.put(url, updatedBomData);
                resolve(`Bill of material with ID ${bomId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteBillOfMaterial(productId, bomId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/bill-of-materials/${bomId}`;
                await axios.delete(url);
                resolve(`Bill of material with ID ${bomId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    //Compatible With operations

    static createCompatibleWith(productId, compatibleWithData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/compatible-with`;
                const result = await axios.post(url, compatibleWithData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateCompatibleWith(productId, compatibleWithId, updatedCompatibleWithData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/compatible-with/${compatibleWithId}`;
                await axios.put(url, updatedCompatibleWithData);
                resolve(`Compatible product with ID ${compatibleWithId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteCompatibleWith(productId, compatibleWithId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/compatible-with/${compatibleWithId}`;
                await axios.delete(url);
                resolve(`Compatible product with ID ${compatibleWithId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Unit Cost operations
    static createUnitCost(productId, unitCostData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/unit-cost-details`;
                const result = await axios.post(url, unitCostData);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }


    static updateUnitCost(productId, unitCostData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${productUrl}/${productId}/unit-cost-details`;
                await axios.put(url, unitCostData);
                resolve(`Unit cost updated successfully for product ID ${productId}`);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default ProductServiceV2;
