<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Stock Details
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
          @click="updateStockDetails"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Stock</span>
      </button>
    </div>

    <div class="flex flex-wrap -my-3 -mx-1">
      <!-- Row 1 -->
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Warehouse</label>
        <input
            type="text"
            v-model="stockDetails.warehouse"
            class="bge-input bge-input-spacing rounded"
            @input="calculateFullLocation"
        />
      </div>
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Rack</label>
        <input
            type="text"
            v-model="stockDetails.rack"
            class="bge-input bge-input-spacing rounded"
            @input="calculateFullLocation"
        />
      </div>
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Level</label>
        <input
            type="text"
            v-model="stockDetails.level"
            class="bge-input bge-input-spacing rounded"
            @input="calculateFullLocation"
        />
      </div>
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Bin</label>
        <input
            type="text"
            v-model="stockDetails.bin"
            class="bge-input bge-input-spacing rounded"
            @input="calculateFullLocation"
        />
      </div>

      <!-- Row 2 -->
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Quantity Available</label>
        <input type="text" v-model="stockDetails.quantity_available" class="bge-input bge-input-spacing rounded" />
      </div>
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Reorder Stock Level</label>
        <input type="text" v-model="stockDetails.reorder_stock_level" class="bge-input bge-input-spacing rounded" />
      </div>
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Reorder Quantity</label>
        <input type="text" v-model="stockDetails.reorder_quantity" class="bge-input bge-input-spacing rounded" />
      </div>
      <div class="w-1/4 flex flex-col px-2 mb-3">
        <label class="uppercase font-semibold text-sm">Full Location</label>
        <input type="text" :value="stockDetails.full_location" class="bge-input bge-input-spacing rounded" disabled />
      </div>
    </div>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stockDetails: {
        warehouse: this.product.warehouse || "",
        rack: this.product.rack || "",
        level: this.product.level || "",
        bin: this.product.bin || "",
        quantity_available: this.product.quantity_available || 0,
        reorder_stock_level: this.product.reorder_stock_level || 0,
        reorder_quantity: this.product.reorder_quantity || 0,
        full_location: "",
      },
    };
  },
  mounted() {
    this.calculateFullLocation();
  },
  methods: {
    calculateFullLocation() {
      const { warehouse, rack, level, bin } = this.stockDetails;
      this.stockDetails.full_location = `${warehouse || ""}.${rack || ""}.${level || ""}.${bin || ""}`;
    },
    async updateStockDetails() {
      try {
        await this.ProductServiceV2.updateProduct(this.product.product_id, this.stockDetails);
        this.$emit("complete");
        this.notifySuccess("Stock details updated successfully.");
      } catch (error) {
        console.error("Error updating stock details:", error);
        this.notifyError("Failed to update stock details.");
      }
    },
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
