<template>
  <div>
    <div v-if="isBusy" class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
      <ProgressSpinner  style="width:50px;height:50px"/>
    </div>
    <div class="flex items-center mb-5 w-full">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Images
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <AppButton color="green" label="Add Images" @click="newImage" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="add" />
        </template>
      </AppButton>
    </div>

    <div class="flex flex-wrap -my-3 -mx-5">
      <div class="flex flex-col w-full my-3 px-5">
        <p
          class="text-center"
          v-if="images.length == 0"
        >No images have been added to this product yet</p>
        <div class="mb-5" v-if="isUploading">
          <!-- <p class="font-bold text-lg mb-2">Uploading image {{ image.name ? image.name : '' }}</p> -->
          <progress-bar :progress="uploadProgress" />
        </div>

        <Galleria
            :value="images"
            :activeIndex.sync="activeIndex"
            :responsiveOptions="responsiveOptions"
            :numVisible="7"
            containerStyle="max-width: 850px"
            :circular="true"
            :fullScreen="true"
            :showItemNavigators="true"
            :showThumbnails="false"
            :visible.sync="displayCustom"
        >
          <template #item="slotProps">
            <img
                :src="slotProps.item.access_url"
                :alt="slotProps.item.name"
                style="width: 100%; display: block"
            />
          </template>
          <template #thumbnail="slotProps">
            <img
                :src="slotProps.item.access_url_thumbnail"
                :alt="slotProps.item.name"
                style="display: block"
            />
          </template>
        </Galleria>

        <div class="flex flex-wrap -my-2">
          <template v-for="(img, idx) in images">
            <div :key="idx" class="p-4 pr-16 pb-6 w-80 mx-2 my-2 bg-gray-100 rounded-lg shadow-md relative">


            <!-- Outer Square -->
              <div class="p-2 pt-3 pl-3 bg-white border border-gray-300 rounded-lg shadow">
                <!-- Inner Image Placeholder -->
                <div class="h-40 w-40 bg-white rounded flex items-center justify-center mx-auto">
                  <img
                      v-if="img.access_url_thumbnail"
                      class="h-full w-auto max-h-full max-w-full object-contain"
                      :src="img.access_url_thumbnail"
                      alt="Thumbnail"
                  />
                  <p v-else class="text-gray-500">Image Placeholder</p>
                </div>
              </div>

              <!-- Image Details -->
              <div class="mt-4" style="height: 68px; width: 200px;">
                <p class="text-sm font-semibold text-gray-700 truncate" :title="img.name ? img.name : 'No Name Available'">
                  {{ img.name ? img.name : 'No Name Available' }}
                </p>
                <p class="text-sm text-gray-500 truncate" :title="img.description ? img.description : 'No Description Available'">
                  {{ img.description ? img.description : 'No Description Available' }}
                </p>
                <p class="text-sm text-gray-400 truncate" :title="img.original_filename ? img.original_filename : 'No Filename Available'">
                  {{ img.original_filename ? img.original_filename : 'No Filename Available' }}
                </p>
              </div>

              <!-- Buttons on Top-Right Corner -->
              <div class="absolute top-4 right-4 flex flex-col space-y-2">
                <!-- Edit Button -->
                <button
                    @click="editImage(img)"
                    class="w-8 h-8 bg-blue-200 hover:bg-blue-300 text-secondary rounded-md p-1 border hover:shadow active:shadow-inner"
                    title="Edit"
                >
                  <svg
                      class="h-5 w-5 stroke-current"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                  </svg>
                </button>
                <!-- Delete Button -->
                <button
                    @click="deleteImage(img)"
                    class="w-8 h-8 bg-red-200 hover:bg-red-300 text-secondary rounded-md p-1 border hover:shadow active:shadow-inner"
                    title="Delete"
                >
                  <svg
                      class="h-5 w-5 stroke-current"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                  </svg>
                </button>
                <!-- View Button -->
                <button
                    @click="imageClick(idx)"
                    class="w-8 h-8 bg-orange-200 hover:bg-orange-300 text-secondary rounded-md p-1 border hover:shadow active:shadow-inner"
                    title="View"
                >
                  <svg
                      class="h-5 w-5 stroke-current"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <path d="M21 21l-4.35-4.35" />
                  </svg>
                </button>

              </div>
              <div class="absolute bottom-4 right-4 flex flex-col space-y-2">
                <InputSwitch v-model="img.is_default" @change="toggleDefault(img)" />
              </div>

            </div>
          </template>
        </div>


      </div>
    </div>

    <panel
      :showing="addEditImagePanelShowing"
      @close="addEditImagePanelShowing = false"
      title="Add Image"
    >
      <add-edit-image :images="editingImages" :uploadRefId="product.product_id" @complete="closeImagePanel" />
    </panel>

    <confirm-alert
      :showingConfirm="confirmAlert.showing"
      :message="confirmAlert.message"
      @complete="confirmAlert.resultFunction"
    />
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import AddEditImage from "@/components/Products/Edit/Images/AddEditImage.vue";
import notificationMixin from "@/mixins/notificationMixin";
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";

export default {
  mixins: [notificationMixin],
  name: "Images",
  components: {
    Icon, AppButton,
    Panel,
    AddEditImage,
    Notification,
    ConfirmAlert,
    ProgressBar
  },
  props: {
    product: Object
  },
  data() {
    return {
      images: [],
      addEditImagePanelShowing: false,
      editingImages: {},
      uploadProgress: 0,
      isUploading: false,
      uploadRefId: "",
      activeIndex: 0,
      displayCustom: false,
      responsiveOptions: [],
      isBusy:false,
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: ""
      }
    };
  },
  async mounted() {
    this.uploadRefId=this.product.product_id;
    await this.loadFiles();

  },
  methods: {
    async toggleDefault(image) {
      try {
        this.isBusy=true;
        const editingDoc = {
          is_default: image.is_default, // The new default state
        };

        // Call the updateDocument service method
        const updatedDocument = await this.ProductService.updateDocument(
            image.document_id,   // Document ID
            this.uploadRefId,    // Product ID (uploadRefId)
            editingDoc           // Update payload
        );

        // If the update is successful, reload the images to reflect changes
        if (updatedDocument) {
          await this.loadFiles();
          this.notifySuccess(`Image '${image.name || image.original_filename}' updated successfully.`);
          this.$emit("complete");
        }
      } catch (error) {
        console.error("Error updating image default state:", error);
        this.notifyError("Failed to update the image's default state.");
      }
      finally {
        this.isBusy = false; // Stop showing the spinner
      }
    },
    async loadFiles() {
      this.isBusy = true; // Start showing the spinner
      try {
        if (!this.uploadRefId) {
          return false;
        }

        const files = await this.ProductService.getProductDocuments(
            this.uploadRefId,
            'attachment'
        );

        const filesWithUniqueId = files.map((file, index) => ({
          ...file,
          unique_id: this.generateUniqueId(),
        }));

        this.images = filesWithUniqueId;
      } catch (error) {
        console.error("Error loading files:", error);
        this.notifyError("There was an error loading the files.");
      } finally {
        this.isBusy = false; // Stop showing the spinner
      }
    },

    newImage: function() {
      this.editingImages = [];
      this.addEditImagePanelShowing = true;
    },
    editImage: function(image) {
      this.editingImages = [image];
      this.addEditImagePanelShowing = true;
    },
    closeImagePanel: async function() {
      this.addEditImagePanelShowing = false;
      await this.loadFiles();
      this.$emit("complete");
    },

    deleteImage: async function(image) {
      try {
        this.isBusy=true;
        // Confirm deletion
        const confirmed = await this.confirmAction({
          message: `Are you sure you want to delete '${image.name || image.original_filename}'? This action cannot be undone.`,
        });

        if (!confirmed) {
          return;
        }

        let fileDeleted = false;

        // Delete local files
         if (this.uploadRefId) {
          // Delete server files
          await this.ProductService.deleteProductDocuments(this.uploadRefId, [image.document_id]);
          debugger;
          this.images = this.images.filter(
              (file) => file.document_id !== image.document_id
          );
          fileDeleted = true;
        }

        // Notify user
        if (fileDeleted) {
          this.notifySuccess("Image deleted successfully.");
          await this.loadFiles()
          this.$emit("complete");
        } else {
          this.notifyWarning("Failed to delete the image.");
        }
      } catch (error) {
        console.error("Failed to delete image:", error);
        this.notifyError("There was an error deleting the image.");
      }
     finally {
      this.isBusy = false; // Stop showing the spinner
    }
    },


    generateUniqueId() {
      return `id_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
    },

    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
  }
};
</script>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>