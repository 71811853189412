<template>
  <div>
    <p :class="`text-secondary font-semibold mb-1 ${textSize}`">Verified</p>
    <div
        :class="`flex items-center rounded-lg ${paddingSize} ${
        isVerified ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'
      }`"
    >
      <!-- Check or Cross Icon -->
      <svg
          v-if="isVerified"
          :class="`${iconSize} mr-2`"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
      >
        <path d="M9 12l2 2l4 -4" />
        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
      </svg>
      <svg
          v-else
          :class="`${iconSize} mr-2`"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
      >
        <path d="M10 8l4 8" />
        <path d="M10 16l4 -8" />
        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
      </svg>
      <!-- Verified or Not Verified Text -->
      <span :class="`font-semibold ${textSize}`">{{ date }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVerified: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'h-6 w-6', // Default icon size
    },
    paddingSize: {
      type: String,
      default: 'py-2 px-4', // Default padding size
    },
    textSize: {
      type: String,
      default: 'text-sm', // Default text size
    },
  },
};
</script>
