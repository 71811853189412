<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Compatible With
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <AppButton color="green" label="Add Products" @click="addNewCompatible" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="add" />
        </template>
      </AppButton>
    </div>

    <!-- Compatible With Table -->
    <div>
      <DataTable
          :value="compatibleWithList"
          dataKey="compatible_with_id"
          :paginator="true"
          :rows="10"
          :lazy="false"
      >
        <!-- Compatible With Columns -->
        <Column field="name" header="Name" :headerStyle="{ width: '30%' }"></Column>
        <Column field="productCode" header="Product Code" :headerStyle="{ width: '20%' }"></Column>
        <Column field="description" header="Description" :headerStyle="{ width: '30%' }"></Column>
        <Column field="category" header="Category" :headerStyle="{ width: '20%' }"></Column>
        <Column header="" :headerStyle="{ width: '10%', textAlign: 'center' }">
          <template #body="slotProps">
            <div class="flex justify-center space-x-2">
              <AppButton color="red" label="" :icon="true" @click="deleteCompatible(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="delete" />
                </template>
              </AppButton>
            </div>
          </template>
        </Column>
        <template #empty>
          <div class="w-full text-center text-gray-500 py-4">No compatible products available.</div>
        </template>
      </DataTable>
    </div>

    <!-- Add/Edit Compatible With Panel -->
    <portal to="overlay-outlet">
      <panel
          :showing="addCompatiblePanelOpen"
          @close="onPanelClose"
          title="Compatible Product Details"
      >
        <AddCompatibleWith
            :product_id="product.product_id"
            :compatibleWithDetailsProp="selectedCompatible"
            @complete="handleEditComplete"
        />
      </panel>
    </portal>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import AddCompatibleWith from "@/components/Products/CompatibleWith/AddCompatibleWith.vue";
import Panel from "@/components/Panel.vue";
import notificationMixin from "@/mixins/notificationMixin";
import AddLineItem from "@/components/Products/LineItem/AddLineItem.vue";

export default {
  name: "CompatibleWithList",
  mixins: [notificationMixin],
  components: {AddLineItem, Panel, AddCompatibleWith, Icon, AppButton },
  props: {
    product: {
      type: Object,
      required: true,
    },
    compatibleWith: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addCompatiblePanelOpen: false,
      selectedCompatible: null,
    };
  },
  computed: {
    compatibleWithList() {
      return this.product.compatible_with.map((compatible) => {
        return {
          name: compatible.name || "N/A",
          productCode: compatible.product_code || "N/A",
          description: compatible.description || "N/A",
          category: compatible.category || "N/A",
          compatibleWithId: compatible.compatible_with_id,
        };
      });
    },
  },
  methods: {
    addNewCompatible() {
      this.addCompatiblePanelOpen = true;
      this.$emit("addCompatible");
    },
    editCompatible(compatible) {
      this.selectedCompatible = this.product.compatible_with.find(
          (item) => item.compatible_with_id === compatible.compatibleWithId
      );
      this.addCompatiblePanelOpen = true;
    },
    async deleteCompatible(compatible) {
      try {
        if (!compatible || !compatible.compatibleWithId) {
          this.notifyError("Cannot delete: Compatible product ID is missing.");
          return;
        }

        const confirmation = await this.confirmAction({
          message: `Do you want to delete the compatible product "${compatible.name || 'this item'}"?`,
          header: "Confirm Action",
          acceptLabel: "Yes",
          rejectLabel: "No",
        });

        if (!confirmation) {
          this.notifyInfo("Deletion canceled.");
          return;
        }

        await this.ProductServiceV2.deleteCompatibleWith(
            this.product.product_id,
            compatible.compatibleWithId
        );

        this.notifySuccess(`Compatible product "${compatible.name || 'Compatible Product'}" deleted successfully.`);
        this.$emit("complete");
      } catch (error) {
        console.error("Error deleting compatible product:", error);
        this.notifyError("An error occurred while deleting compatible product.");
      }
    },
    onPanelClose() {
      this.selectedCompatible = null;
      this.addCompatiblePanelOpen = false;
    },
    async handleEditComplete() {
      this.$emit("complete");
      this.onPanelClose();
    },
  },
};
</script>

<style scoped>
::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
