import moment from "moment";
import ProductServiceV2 from "@/services/productServiceV2";

export const ProductStore = {
    namespaced: true,
    state: {
        tabs: {
            products: {
                product_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "created_at",
                sortOrder: "-1",
                first: 0,
            },
            archive: {
                product_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "created_at",
                sortOrder: "-1",
                first: 0,
            },
            search: {
                searchTerm: "",
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "created_at",
                sortOrder: "-1",
                first: 0,
            },
        },
        currentTab: "products",
        searchText: "",
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, { tab, data }) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, { tab, page }) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, { tab, rows }) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, { tab, field, order }) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        RESET_FIRST_VALUE(state, { tab }) {
            const currentPage = state.tabs[tab].currentPage;
            const rowsPerPage = state.tabs[tab].rowsPerPage;
            state.tabs[tab].first = currentPage > 0 ? (currentPage - 1) * rowsPerPage : 0;
        },
        RESET_CURRENT_PAGE(state, { tab, page = 0 }) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            state.searchText = searchText;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
    },
    actions: {
        async fetchTableData({ commit, state, dispatch }) {
            commit("SET_LOADING", true);
            const currentTabState = state.tabs[state.currentTab];

            let params = buildParams(currentTabState, state);
            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);

                commit("SET_TABLE_DATA", { tab: state.currentTab, data });
            } catch (error) {
                commit("SET_TABLE_DATA", { tab: state.currentTab, data: [] });
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
                dispatch("resetFirstValue");
            }
        },
        updateTableSettings({ commit, dispatch, state }, { rows, page }) {
            commit("UPDATE_ROWS_PER_PAGE", { tab: state.currentTab, rows });
            commit("UPDATE_CURRENT_PAGE", { tab: state.currentTab, page: page + 1 });
            dispatch("fetchTableData");
        },
        updateSortOptions({ commit, dispatch, state }, { field, order }) {
            commit("UPDATE_SORT_OPTIONS", { tab: state.currentTab, field, order });
            dispatch("fetchTableData");
        },
        updateSearchCriteria({ commit, dispatch }, { searchText }) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            dispatch("fetchTableData");
        },
        updateTab({ commit, dispatch }, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
        resetFirstValue({ commit, state }) {
            commit("RESET_FIRST_VALUE", { tab: state.currentTab });
        },
        resetCurrentPage({ commit, state }, page = 0) {
            commit("RESET_CURRENT_PAGE", { tab: state.currentTab, page });
        },
    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {
    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        tab: state.currentTab,
    };

    if (state.searchText && state.currentTab=='search') {
        params.searchTerm = state.searchText;
    }
   if (state.currentTab=='archive') {
        params.isArchived = true;
    }

    return params;
}

function getApiEndpoint() {
    return ProductServiceV2.searchProducts;
}
