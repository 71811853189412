<template>
  <div>
    <div class="flex items-center mb-5 w-full">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Price Bands
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <AppButton color="green" label="Add Price Band" @click="addNewPriceBand" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="add" />
        </template>
      </AppButton>
    </div>

    <!-- Price Band Table -->
    <div>
      <DataTable
          :value="priceBandList"
          dataKey="price_band_id"
          :paginator="true"
          :rows="10"
          :lazy="false"
      >
        <!-- Price Band Columns -->
        <Column field="bandCode" header="Band Code" :headerStyle="{ width: '15%' }"></Column>
        <Column field="description" header="Description" :headerStyle="{ width: '25%' }"></Column>
        <Column field="price" header="Price" :headerStyle="{ width: '15%' }" headerClass="column-h-text-center" :styles="{ 'text-align': 'right' }"></Column>
        <Column field="deliveryPrice" header="Delivery Price" :headerStyle="{ width: '15%' }" headerClass="column-h-text-center" :styles="{ 'text-align': 'right' }"></Column>
        <Column header="" :headerStyle="{ width: '10%', textAlign: 'center' }">
          <template #body="slotProps">
            <div class="flex justify-center space-x-2">
              <AppButton color="blue" label="" :icon="true" @click="editPriceBand(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="edit" />
                </template>
              </AppButton>
              <AppButton color="red" label="" :icon="true" @click="deletePriceBand(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="delete" />
                </template>
              </AppButton>
            </div>
          </template>
        </Column>
        <template #empty>
          <div class="w-full text-center text-gray-500 py-4">No price bands available.</div>
        </template>
      </DataTable>
    </div>

    <!-- Add/Edit Price Band Panel -->
    <portal to="overlay-outlet">
      <panel
          :showing="addPriceBandPanelOpen"
          @close="onPanelClose"
          title="Price Band Details"
      >
        <AddPriceBand
            :product_id="product.product_id"
            :priceBandDetailsProp="selectedPriceBand"
            @complete="onPanelClose"
        />
      </panel>
    </portal>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import AddPriceBand from "@/components/Products/Price/AddPriceBand.vue";
import Panel from "@/components/Panel.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "PriceBandList",
  mixins: [notificationMixin],
  components: { Panel, AddPriceBand, Icon, AppButton },
  props: {
    product: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      addPriceBandPanelOpen: false,
      selectedPriceBand: null,
    };
  },
  computed: {
    priceBandList() {
      return this.product.price_bands.map((priceBand) => {
        const formatCurrency = (value) => `£${parseFloat(value || 0).toFixed(2)}`;
        return {
          bandCode: priceBand.band_code || "N/A",
          description: priceBand.description || "N/A",
          price: formatCurrency(priceBand.price),
          deliveryPrice: formatCurrency(priceBand.delivery_price),
          priceBandId: priceBand.price_band_id,
        };
      });
    },
  },
  methods: {
    addNewPriceBand() {
      this.addPriceBandPanelOpen = true;
      this.$emit("addPriceBand");
    },
    editPriceBand(priceBand) {
      debugger;
      this.selectedPriceBand = this.product.price_bands.find(
          (item) => item.price_band_id === priceBand.priceBandId
      );
      this.addPriceBandPanelOpen = true;
    },
    async deletePriceBand(priceBand) {
      try {
        if (!priceBand || !priceBand.priceBandId) {
          this.notifyError("Cannot delete: Price band ID is missing.");
          return;
        }

        const confirmation = await this.confirmAction({
          message: `Do you want to delete the price band "${priceBand.description || 'this band'}"?`,
          header: "Confirm Action",
          acceptLabel: "Yes",
          rejectLabel: "No",
        });

        if (!confirmation) {
          this.notifyInfo("Deletion canceled.");
          return;
        }

        await this.ProductServiceV2.deletePriceBand(
            this.product.product_id,
            priceBand.priceBandId
        );

        this.notifySuccess(`Price band "${priceBand.description || 'Price Band'}" deleted successfully.`);
        this.$emit("complete");
      } catch (error) {
        console.error("Error deleting price band:", error);
        this.notifyError("An error occurred while deleting the price band.");
      }
    },
    onPanelClose() {
      this.selectedPriceBand = null;
      this.addPriceBandPanelOpen = false;
      this.$emit("complete");
    },
  },
};
</script>

<style scoped>
::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
