<template>
  <div class="flex">
    <!-- Left Section: 65% -->
    <div class="w-3/5 pr-5 relative">
      <div v-if="isLoading" class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
        <ProgressSpinner style="width:50px;height:50px"/>
      </div>

      <div class="flex items-center mb-5">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
          Product Details
        </h3>
        <hr class="flex-1 border-primary mx-4" />
        <button
            @click="updateProduct"
            class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
              class="stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
          >
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Product</span>
        </button>
      </div>

      <div class="flex flex-wrap -my-3 -mx-1">
        <!-- Row 1 -->
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Product Category</label>
          <select v-model="productDetails.category" class="bge-input bge-input-spacing rounded">
            <option v-for="category in productCategories" :key="category.data_master_id" :value="category.value">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">VAT Code</label>
          <select v-model="productDetails.vat_code" class="bge-input bge-input-spacing rounded">
            <option v-for="vat in vatCodes" :key="vat.data_master_id" :value="vat.description">
              {{ vat.name }} ({{ vat.description }})
            </option>
          </select>
        </div>
        <!-- Calibration Interval Dropdown -->
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Calibration Interval <span class="text-xs">(MTHS)</span></label>
          <select v-model="productDetails.calibration_interval" class="bge-input bge-input-spacing rounded">
            <option v-for="interval in calibrationIntervals" :key="interval.data_master_id" :value="interval.value">
              {{ interval.name }}
            </option>
          </select>
        </div>

        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Shipping Method</label>
          <select v-model="productDetails.shipping_method" class="bge-input bge-input-spacing rounded">
            <option v-for="method in shippingMethods" :key="method.data_master_id" :value="method.value">
              {{ method.name }}
            </option>
          </select>
        </div>

        <!-- Row 2 -->
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Product Type</label>
          <select v-model="productDetails.type" class="bge-input bge-input-spacing rounded">
            <option v-for="type in productTypes" :key="type.data_master_id" :value="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">HS Code</label>
          <input type="text" v-model="productDetails.hs_code" class="bge-input bge-input-spacing rounded" />
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Service Interval <span class="text-xs">(MTHS)</span></label>
          <select v-model="productDetails.service_interval" class="bge-input bge-input-spacing rounded">
            <option v-for="interval in serviceIntervals" :key="interval.data_master_id" :value="interval.value">
              {{ interval.name }}
            </option>
          </select>
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Net Weight <span class="text-xs">(kg)</span></label>
          <input type="text" v-model="productDetails.net_weight" class="bge-input bge-input-spacing rounded" />
        </div>

        <!-- Row 3 -->
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Show on Web</label>
          <select v-model="productDetails.show_on_web" class="bge-input bge-input-spacing rounded">
            <option v-for="option in showOnWebOptions" :key="option.data_master_id" :value="option.value">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Nominal Code</label>
          <input type="text" v-model="productDetails.nominal_code" class="bge-input bge-input-spacing rounded" />
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Warranty Period <span class="text-xs">(MTHS)</span></label>
          <select v-model="productDetails.warranty_period" class="bge-input bge-input-spacing rounded">
            <option v-for="period in warrantyPeriods" :key="period.data_master_id" :value="period.value">
              {{ period.name }}
            </option>
          </select>
        </div>
        <div class="w-1/4 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Gross Weight <span class="text-xs">(kg)</span></label>
          <input type="text" v-model="productDetails.gross_weight" class="bge-input bge-input-spacing rounded" />
        </div>
      </div>
    </div>

    <!-- Right Section: 35% -->
    <div class="w-2/5 bg-gray-100 p-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Shipping Details
      </h3>
      <ShippingDetails
          :product_id="product.product_id"
          :initialShippingDetails="product.shipping_details"
          :shipping_method="product.shipping_method"
          @complete="handleEditComplete"
      />
    </div>
  </div>
</template>


<script>
import notificationMixin from "@/mixins/notificationMixin";
import ShippingDetails from "@/components/Products/Details/ShippingDetails.vue";
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";

export default {
  mixins: [notificationMixin],
  components: { AppButton, Icon ,ShippingDetails},
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productDetails: { ...this.product },
      productCategories: [],
      vatCodes: [],
      shippingMethods: [],
      productTypes: [],
      showOnWebOptions: [],
      serviceIntervals: [],
      warrantyPeriods: [],
      calibrationIntervals: [],
      isLoading:false,
    };
  },

  watch: {
    product: {
      handler(newProduct) {
        this.productDetails = { ...newProduct };
      },
      deep: true,
    },
  },

  async mounted() {
    await this.fetchDropdownData();
  },
  methods: {
    async fetchDropdownData() {
      try {
        this.isLoading = true; // Start loading spinner
        await Promise.all([
          this.fetchProductCategories(),
          this.fetchVatCodes(),
          this.fetchShippingMethods(),
          this.fetchProductTypes(),
          this.fetchShowOnWebOptions(),
          this.fetchServiceIntervals(),
          this.fetchWarrantyPeriods(),
          this.fetchCalibrationIntervals(),
        ]);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      } finally {
        this.isLoading = false; // Stop loading spinner
      }
    },
    async fetchProductCategories() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=ProductCategory&is_active=true&noPaging=true&orderBy=order&order=1');
        this.productCategories = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching product categories:", error);
      }
    },
    async fetchVatCodes() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=PO_Tax_Code&is_active=true&noPaging=true&orderBy=order&order=1');
        this.vatCodes = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching VAT codes:", error);
      }
    },
    async fetchShippingMethods() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=ShippingMethod&is_active=true&noPaging=true&orderBy=order&order=1');
        this.shippingMethods = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching shipping methods:", error);
      }
    },
    async fetchProductTypes() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=ProductType&is_active=true&noPaging=true&orderBy=order&order=1');
        this.productTypes = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    },
    async fetchShowOnWebOptions() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=ShowOnWeb&is_active=true&noPaging=true&orderBy=order&order=1');
        this.showOnWebOptions = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching show on web options:", error);
      }
    },
    async fetchServiceIntervals() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=ServiceInterval&is_active=true&noPaging=true&orderBy=order&order=1');
        this.serviceIntervals = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching service intervals:", error);
      }
    },
    async fetchWarrantyPeriods() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=WarrantyPeriod&is_active=true&noPaging=true&orderBy=order&order=1');
        this.warrantyPeriods = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching warranty periods:", error);
      }
    },
    async fetchCalibrationIntervals() {
      try {
        const response = await this.DatamasterService.getDataMasters('?type=CalibrationInterval&is_active=true&noPaging=true&orderBy=order&order=1');
        this.calibrationIntervals = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching calibration intervals:", error);
      }
    },

    mapDropdownOptions(response) {
      if (!response || !Array.isArray(response)) {
        console.error("Invalid response passed to mapDropdownOptions:", response);
        return [];
      }
      return response.map((item) => ({
        data_master_id: item.data_master_id,
        name: item.name,
        value: item.value || '',
        description: item.description || '',
      }));
    },
    async updateProduct() {
      try {
        await this.ProductServiceV2.updateProduct(this.productDetails.product_id, this.productDetails);
        this.notifySuccess("Product updated successfully.");
        this.$emit("complete");
      } catch (error) {
        this.notifyError("Error updating product details.");
        console.error(error);
      }
    },
    async handleEditComplete() {
      this.$emit("complete");
    },
    cancelEdit() {
      // Logic to cancel editing
    },

  },
};
</script>
