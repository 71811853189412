<template>
  <div>
    <!-- Auto Complete for Documents -->
    <div class="w-full mb-5">
      <label class="uppercase font-semibold text-sm">
        Select Existing Document
        <span class="text-red-500">*</span>
      </label>
      <AutoComplete
          :multiple="true"
          v-model="selectedDocuments"
          field="document_number"
          :suggestions="filteredDocuments"
          @complete="searchDocuments($event)"
          placeholder="Type here to search for documents"
          class="w-full"
      >
        <template #item="{ item }">
          <div class="autocomplete-row">
            <div class="autocomplete-code" :title="item.document_number">
              {{ item.document_number }}
            </div>
            <div class="autocomplete-name" :title="item.name">
              {{ item.name }}
            </div>
            <div class="autocomplete-category" :title="item.category">
              {{ item.category }}
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>

    <!-- Add Documents Button -->
    <div class="relative flex justify-end mt-5">
      <div
          v-show="!canAddDocuments"
          class="opacity-50 bg-white absolute inset-0"
      ></div>
      <button
          class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
          :disabled="!canAddDocuments"
          @click="addExistingDocuments"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Add Documents</span>
      </button>
    </div>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "AddDocuments",
  mixins: [notificationMixin],
  components: {
  },
  props: {
    product_id: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDocuments: [],
      filteredDocuments: null,
    };
  },
  computed: {
    canAddDocuments() {
      return this.selectedDocuments.length > 0;
    },
  },
  methods: {
    async searchDocuments(event) {
      try {
        const result = await this.DocumentService.searchDocuments(
            event.query.toLowerCase()
        );
        this.filteredDocuments = result.data;
      } catch (error) {
        console.error("Error searching documents:", error);
        this.notifyError("Unable to search for documents.");
      }
    },
    async addExistingDocuments() {
      try {
        const promises = this.selectedDocuments.map(async (document) => {
          await this.DocumentService.attachResource(document.document_id,
              {
                resource_type: "product",
                resource_id: this.product_id,
              },
          );
        });

        await Promise.all(promises);

        this.notifySuccess("Documents added successfully.");
        this.$emit("complete"); // Emit an event to refresh the parent list
      } catch (error) {
        console.error("Error adding documents:", error);
        this.notifyError("Unable to add documents.");
      }
    },
  },
};
</script>

<style scoped>
.autocomplete-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.autocomplete-code,
.autocomplete-name,
.autocomplete-category {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-code {
  width: 20%;
}

.autocomplete-name {
  width: 50%;
}

.autocomplete-category {
  width: 30%;
}
</style>
