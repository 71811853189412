<template>
  <div class="card">
    <DataTable :value="localShippingDetails" dataKey="id" :loading=isLoading>
      <!-- Shipping Method -->
      <Column field="shipping_method" header="Shipping Method">
        <template #body="slotProps">
          <input
              v-if="editableRowId === slotProps.data.id"
              type="text"
              v-model="slotProps.data.shipping_method"
              class="w-full bge-input bge-input-spacing rounded"
          />
          <span v-else>{{ slotProps.data.shipping_method }}</span>
        </template>
      </Column>

      <!-- Dimensions (mm) -->
      <Column field="dimensions" header="Dimensions (mm)">
        <template #body="slotProps">
          <input
              v-if="editableRowId === slotProps.data.id"
              type="text"
              v-model="slotProps.data.dimensions"
              class="w-full bge-input bge-input-spacing rounded"
          />
          <span v-else>{{ slotProps.data.dimensions }}</span>
        </template>
      </Column>

      <!-- Weight (kg) -->
      <Column field="weight" header="Weight (kg)">
        <template #body="slotProps">
          <input
              v-if="editableRowId === slotProps.data.id"
              type="text"
              v-model="slotProps.data.weight"
              class="w-full bge-input bge-input-spacing rounded"
          />
          <span v-else>{{ slotProps.data.weight }}</span>
        </template>
      </Column>

      <!-- Actions -->
      <Column header="">
        <template #body="slotProps">
          <div class="flex justify-end items-center">
            <!-- Save Button -->
            <AppButton
                v-if="editableRowId === slotProps.data.id"
                color="blue"
                label=""
                :icon="true"
                class="mr-1"
                @click="saveRow(slotProps.data)"
            >
              <template v-slot:icon>
                <Icon iconType="save" />
              </template>
            </AppButton>
            <!-- Cancel Button -->
            <AppButton
                v-if="editableRowId === slotProps.data.id"
                color="gray"
                label=""
                :icon="true"
                class="mr-1"
                @click="cancelEdit(slotProps.data)"
            >
              <template v-slot:icon>
                <Icon iconType="close" />
              </template>
            </AppButton>
            <!-- Edit Button -->
            <AppButton
                v-else
                color="blue"
                label=""
                :icon="true"
                class="mr-1"
                @click="editRow(slotProps.data.id)"
            >
              <template v-slot:icon>
                <Icon iconType="edit" />
              </template>
            </AppButton>
            <!-- Delete Button -->
            <AppButton
                color="red"
                label=""
                :icon="true"
                class="mr-1"
                @click="deleteRow(slotProps.data.id)"
            >
              <template v-slot:icon>
                <Icon iconType="delete" />
              </template>
            </AppButton>
            <!-- Add New Button -->
            <AppButton
                v-if="isLastRow(slotProps.data.id)"
                color="green"
                label=""
                :icon="true"
                class="mr-1"
                @click="addShippingDetail"
            >
              <template v-slot:icon>
                <Icon iconType="add-notes" />
              </template>
            </AppButton>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import _ from "lodash";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: { AppButton, Icon },
  props: {
    initialShippingDetails: {
      type: Array,
      default: () => [],
    },
    product_id: {
      type: String,
      required: true,
    },
    shipping_method: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      localShippingDetails: [],
      editableRowId: null,
      originalShippingData: {},
      isLoading:false,
    };
  },
  mounted() {
    this.localShippingDetails = _.cloneDeep(this.initialShippingDetails);
    this.checkAndAddInitialShippingDetail();
  },
  watch: {
    initialShippingDetails: {
      handler(newDetails) {
        // Update only if data actually changes
        if (!_.isEqual(this.localShippingDetails, newDetails)) {
          debugger;
          this.localShippingDetails = _.cloneDeep(newDetails);
          this.editableRowId = null;
          this.checkAndAddInitialShippingDetail();
        }
      },
      deep: true,
      immediate: false,
    },
    shipping_method: {
      handler(newMethod) {
        this.refreshShippingDetails(newMethod);
      },
      immediate: false,
    },
  },
  methods: {
    async refreshShippingDetails(newMethod) {
      try {
        this.isLoading = true;

        debugger;

        for (const [index, detail] of this.localShippingDetails.entries()) {
          // Add index only if there is more than one row
          debugger;
          detail.shipping_method = this.localShippingDetails.length > 1
              ? `${newMethod || "Shipping Method"} ${index + 1}`
              : `${newMethod || "Shipping Method"}`;

          if (detail.shipping_detail_id) {
            await this.ProductServiceV2.updateShippingDetail(this.product_id, detail.shipping_detail_id, detail);
            this.$emit("complete");
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.notifyError("Failed to update shipping details.");
        console.error("Error refreshing shipping details:", error);
      }
    },

    async addShippingDetail() {

      debugger;

      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current shipping record before adding a new one.");
        return;
      }
      if (this.localShippingDetails.length === 1) {
        // Update the first row's shipping_method to include " 1"
        const firstRow = this.localShippingDetails[0];
        firstRow.shipping_method = `${this.$props.shipping_method || "Shipping Method"} 1`;

        await this.updateServerShippingMethod(firstRow);
      }

      const methodCount = this.localShippingDetails.length + 1;
      const defaultShippingMethod =
          this.localShippingDetails.length > 0
              ? `${this.$props.shipping_method || "Shipping Method"} ${methodCount}`
              : this.$props.shipping_method || "Shipping Method";

      const newShippingDetail = {
        id: this.generateGUID(),
        shipping_detail_id: null, // Placeholder for server-assigned ID
        shipping_method: defaultShippingMethod,
        dimensions: "",
        weight: "",
        isNew: true,
      };

      this.localShippingDetails.push(newShippingDetail);
      this.editableRowId = newShippingDetail.id;
    },
    async editRow(rowId) {
      const item = this.localShippingDetails.find((item) => item.id === rowId);
      if (item) {
        this.originalShippingData[rowId] = _.cloneDeep(item);
        this.editableRowId = rowId; // Set editable row ID
      }
    },
    async saveRow(item) {
      debugger;

      if (!item.shipping_method) {
        this.notifyError("Shipping method is required.");
        return;
      }

      try {
        if (item.isNew) {
          // Create new shipping detail
          const createdItem = await this.ProductServiceV2.createShippingDetail(this.product_id, item);
          this.$emit("complete");

          if (createdItem) {
            const index = this.localShippingDetails.findIndex((i) => i.id === item.id);
            if (index !== -1) {
              this.$set(this.localShippingDetails, index, {
                ...createdItem,
                isNew: false,
                id: item.id, // Preserve local ID for tracking
              });
            }
          }
        } else {
          // Update existing shipping detail
          await this.ProductServiceV2.updateShippingDetail(this.product_id, item.shipping_detail_id, item);
          this.$emit("complete");

          const index = this.localShippingDetails.findIndex((i) => i.id === item.id);
          if (index !== -1) {
            this.$set(this.localShippingDetails, index, {
              ...this.localShippingDetails[index],
              ...item,
              isNew: false,
            });
          }
        }

        this.notifySuccess("Shipping detail saved successfully.");
        this.editableRowId = null;
      } catch (error) {
        this.notifyError("Failed to save shipping detail.");
        console.error(error);
      }
    },
    async deleteRow(rowId) {
      debugger;

      const itemIndex = this.localShippingDetails.findIndex((item) => item.id === rowId);
      const item = this.localShippingDetails[itemIndex];

      if (!item.shipping_detail_id) {
        this.localShippingDetails.splice(itemIndex, 1);
        await this.reassignShippingMethods();
        return;
      }

      try {
        await this.ProductServiceV2.deleteShippingDetail(this.product_id, item.shipping_detail_id);

        this.localShippingDetails.splice(itemIndex, 1);
        await this.reassignShippingMethods();
        this.$emit("complete");
        this.notifySuccess("Shipping detail deleted successfully.");
      } catch (error) {
        this.notifyError("Failed to delete shipping detail.");
        console.error(error);
      }
    },

    async reassignShippingMethods() {
      debugger;
      if (this.localShippingDetails.length === 1) {
        const detail = this.localShippingDetails[0];
        detail.shipping_method = this.$props.shipping_method || "Shipping Method";
        await this.updateServerShippingMethod(detail);
      } else {
        debugger;
        this.localShippingDetails.forEach((detail, index) => {
          detail.shipping_method = `${this.$props.shipping_method || "Shipping Method"} ${index + 1}`;
          this.updateServerShippingMethod(detail);
        });
      }
    },

    async updateServerShippingMethod(detail) {
      debugger;

      if (!detail.shipping_detail_id) return;
      try {
        await this.ProductServiceV2.updateShippingDetail(this.product_id, detail.shipping_detail_id, detail);
      } catch (error) {

        console.error(error);
      }
    },

    cancelEdit(item) {
      if (item.isNew) {
        this.localShippingDetails = this.localShippingDetails.filter((i) => i.id !== item.id);
      } else {
        const originalData = this.originalShippingData[item.id];
        if (originalData) {
          const index = this.localShippingDetails.findIndex((p) => p.id === item.id);
          this.localShippingDetails.splice(index, 1, originalData);
        }
      }
      this.editableRowId = null;
    },
    isLastRow(rowId) {
      return this.localShippingDetails[this.localShippingDetails.length - 1].id === rowId;
    },
    generateGUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        return c === "x" ? r.toString(16) : ((r & 0x3) | 0x8).toString(16);
      });
    },
    checkAndAddInitialShippingDetail() {
      debugger;

      if (this.localShippingDetails.length === 0) {
        this.addShippingDetail();
      }
    },
  },
};
</script>
