<template>
  <div class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Band Code</label>
      <select
          v-model="priceBandDetails.band_code"
          class="w-full bge-input bge-input-spacing rounded"
          @change="updateDescription"
      >
        <option
            v-for="code in bandCodes"
            :key="code.data_master_id"
            :value="code.value"
        >
          {{code.value}} . {{ code.name }}
        </option>
      </select>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <input
          v-model="priceBandDetails.description"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          placeholder="Description"
          readonly
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Price</label>
      <input
          v-model="priceBandDetails.price"
          class="w-full bge-input bge-input-spacing rounded"
          type="number"
          min="0"
          placeholder="Price"
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Delivery Price</label>
      <input
          v-model="priceBandDetails.delivery_price"
          class="w-full bge-input bge-input-spacing rounded"
          type="number"
          min="0"
          placeholder="Delivery Price"
      />
    </div>

    <!-- Save Button -->
    <div class="flex w-full justify-end mt-4">
      <AppButton color="green" label="Save Price Band" @click="savePriceBandDetails" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="save" class="mr-2" />
        </template>
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "AddPriceBand",
  mixins: [notificationMixin],
  components: {AppButton, Icon},
  props: {
    priceBandDetailsProp: {
      type: Object,
      required: false,
      default: () => null,
    },
    product_id: {
      type: String,
      required: true, // Product ID is mandatory
    },
  },
  data() {
    return {
      priceBandDetails: {
        band_code: "",
        description: "",
        price: 0,
        delivery_price: 0,
      },
      bandCodes: [], // Dynamic dropdown options
    };
  },
  async mounted() {
    if (this.priceBandDetailsProp) {
      this.priceBandDetails = {...this.priceBandDetailsProp};
    }
    await this.fetchBandCodes();
  },
  methods: {
    async fetchBandCodes() {
      try {
        const response = await this.DatamasterService.getDataMasters(
            "?type=PriceBand&is_active=true&noPaging=true&orderBy=order&order=1"
        );
        this.bandCodes = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching band codes:", error);
      }
    },
    mapDropdownOptions(response) {
      return response.map((item) => ({
        data_master_id: item.data_master_id,
        name: item.name,
        value: item.value || "",
      }));
    },
    updateDescription() {
      const selectedBand = this.bandCodes.find(
          (band) => band.value === this.priceBandDetails.band_code
      );
      if (selectedBand) {
        this.priceBandDetails.description = selectedBand.name;
      }
    },
    async savePriceBandDetails() {
      try {
        if (!this.priceBandDetails.band_code || !this.priceBandDetails.description) {
          this.notifyError("Please select a valid Band Code and Description.");
          return;
        }

        if (this.priceBandDetails.price_band_id) {
          // Update existing price band
          await this.ProductServiceV2.updatePriceBand(
              this.product_id,
              this.priceBandDetails.price_band_id,
              this.priceBandDetails
          );
          this.notifySuccess("Price band updated successfully.");
        } else {
          // Create new price band
          const createdBand = await this.ProductServiceV2.createPriceBand(
              this.product_id,
              this.priceBandDetails
          );
          this.priceBandDetails.price_band_id = createdBand.price_band_id;
          this.notifySuccess("Price band created successfully.");
        }

        this.$emit("complete");
      } catch (error) {
        console.error("Error saving price band:", error);
        this.notifyError("An error occurred while saving the price band.");
      }
    },
  },
};
</script>

<style scoped>
.bge-input-spacing {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
