<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Line Items
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <AppButton color="green" label="Add BOM Item" @click="addNewLineItem" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="add" />
        </template>
      </AppButton>
    </div>

    <!-- Line Item Table -->
    <div>
      <DataTable
          :value="lineItemsList"
          dataKey="line_item_id"
          :paginator="true"
          :rows="10"
          :lazy="false"
      >
        <!-- Line Item Columns -->
        <Column field="name" header="Name" :headerStyle="{ width: '20%' }"></Column>
        <Column field="productCode" header="Product Code" :headerStyle="{ width: '10%' }"></Column>
        <Column field="qty" header="Quantity" :headerStyle="{ width: '10%' }"></Column>
        <Column field="packingList" header="Packing List" :headerStyle="{ width: '10%' }" headerClass="column-h-text-center">
          <template #body="slotProps">
            <div class="flex justify-center">
              <InputSwitch
                  v-model="slotProps.data.packingList"
                  disabled
              />
            </div>
          </template>
        </Column>
        <Column field="dispatchNote" header="Dispatch Note" :headerStyle="{ width: '10%' }" headerClass="column-h-text-center">
          <template #body="slotProps">
            <div class="flex justify-center">
              <InputSwitch
                  v-model="slotProps.data.dispatchNote"
                  disabled
              />
            </div>
          </template>
        </Column>
        <Column field="orderConfirmation" header="Order Confirmation" :headerStyle="{ width: '10%' }" headerClass="column-h-text-center">
          <template #body="slotProps">
            <div class="flex justify-center">
              <InputSwitch
                  v-model="slotProps.data.orderConfirmation"
                  disabled
              />
            </div>
          </template>
        </Column>
        <Column field="invoice" header="Invoice" :headerStyle="{ width: '10%' }" headerClass="column-h-text-center">
          <template #body="slotProps">
            <div class="flex justify-center">
              <InputSwitch
                  v-model="slotProps.data.invoice"
                  disabled
              />
            </div>
          </template>
        </Column>
        <Column header="" :headerStyle="{ width: '10%', textAlign: 'center' }">
          <template #body="slotProps">
            <div class="flex justify-center space-x-2">
              <AppButton color="blue" label="" :icon="true" @click="editLineItem(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="edit" />
                </template>
              </AppButton>
              <AppButton color="red" label="" :icon="true" @click="deleteLineItem(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="delete" />
                </template>
              </AppButton>
            </div>
          </template>
        </Column>
        <template #empty>
          <div class="w-full text-center text-gray-500 py-4">No BOM items available.</div>
        </template>
      </DataTable>
    </div>

    <!-- Add/Edit Line Item Panel -->
    <portal to="overlay-outlet">
      <panel
          :showing="addLineItemPanelOpen"
          @close="onPanelClose"
          title="Line Item Details"
      >
        <AddLineItem
            :product_id="product.product_id"
            :lineItemDetailsProp="selectedLineItem"
            @complete="handleEditComplete"
        />
      </panel>
    </portal>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import AddLineItem from "@/components/Products/LineItem/AddLineItem.vue";
import Panel from "@/components/Panel.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "LineItemList",
  mixins: [notificationMixin],
  components: { Panel, AddLineItem, Icon, AppButton },
  props: {
    product: {
      type: Object,
      required: true,
    },
    lineItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addLineItemPanelOpen: false,
      selectedLineItem: null,
    };
  },
  computed: {
    lineItemsList() {
      return this.product.line_items.map((lineItem) => {
        return {
          name: lineItem.name || "N/A",
          productCode: lineItem.product_code || "N/A",
          qty: lineItem.qty || 0,
          packingList: lineItem.packing_list || false,
          dispatchNote: lineItem.dispatch_note || false,
          orderConfirmation: lineItem.order_confirmation || false,
          invoice: lineItem.invoice || false,
          lineItemId: lineItem.line_item_id,
        };
      });
    },
  },
  methods: {
    addNewLineItem() {
      this.addLineItemPanelOpen = true;
      this.$emit("addLineItem");
    },
    editLineItem(lineItem) {
      this.selectedLineItem = this.product.line_items.find(
          (item) => item.line_item_id === lineItem.lineItemId
      );
      this.addLineItemPanelOpen = true;
    },
    async deleteLineItem(lineItem) {
      try {
        if (!lineItem || !lineItem.lineItemId) {
          this.notifyError("Cannot delete: Line item ID is missing.");
          return;
        }

        const confirmation = await this.confirmAction({
          message: `Do you want to delete the line item "${lineItem.name || 'this item'}"?`,
          header: "Confirm Action",
          acceptLabel: "Yes",
          rejectLabel: "No",
        });

        if (!confirmation) {
          this.notifyInfo("Deletion canceled.");
          return;
        }

        await this.ProductServiceV2.deleteLineItem(
            this.product.product_id,
            lineItem.lineItemId
        );

        this.notifySuccess(`Line item "${lineItem.name || 'Line Item'}" deleted successfully.`);
        this.$emit("complete");
      } catch (error) {
        console.error("Error deleting line item:", error);
        this.notifyError("An error occurred while deleting line item.");
      }
    },
    onPanelClose() {
      this.selectedLineItem = null;
      this.addLineItemPanelOpen = false;
    },
    async handleEditComplete() {
      this.$emit("complete");
      this.onPanelClose();
    },
  },
};
</script>

<style scoped>
::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
