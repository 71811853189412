<template>
  <div>
    <div class="flex items-center mb-3">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Cost Information
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
          @click="saveUnitCostDetails"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Cost</span>
      </button>
    </div>

    <div class="flex flex-wrap -my-5 -mx-1 mt-3">
      <div class="w-full flex flex-wrap">
        <!-- Unit Cost -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Unit Cost</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.unit_cost"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- Delivery In -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Delivery In</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.delivery_in"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- VAT In -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">VAT In</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.vat_in"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- Duty In -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Duty In</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.duty_in"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- Processing -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Processing</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.processing"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- Packaging -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Packaging</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.packaging"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- Delivery Out -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Delivery Out</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.delivery_out"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>

        <!-- Total Cost -->
        <div class="w-1/8 flex flex-col px-2 mb-3">
          <label class="uppercase font-semibold text-sm">Total Cost</label>
          <div
              class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
          >
            <div class="flex justify-center items-center p-2">
              <span>£</span>
            </div>
            <input
                v-model="unit_cost_details.total_cost" disabled="disabled"
                class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                type="text"
            />
          </div>
        </div>
      </div>
    </div>


    <div class="w-full flex items-center mb-3 mt-10">
       <PriceBandList class="w-full" :product="product" @complete="completeAction"></PriceBandList>
    </div>




  </div>
</template>

<script>
import PriceBandList from "@/components/Products/Price/PriceBandList.vue";
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
export default {
  components: {AppButton, Icon,PriceBandList},
  mixins: [notificationMixin],
  name: 'PricingDetails',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      unit_cost_details: {
        unit_cost: 0,
        delivery_in: 0,
        vat_in: 0,
        duty_in: 0,
        processing: 0,
        packaging: 0,
        delivery_out: 0,
        total_cost: 0
      }
    };
  },
  watch: {
    // Watch individual fields for changes
    'unit_cost_details.unit_cost': 'calculateTotalCost',
    'unit_cost_details.delivery_in': 'calculateTotalCost',
    'unit_cost_details.vat_in': 'calculateTotalCost',
    'unit_cost_details.duty_in': 'calculateTotalCost',
    'unit_cost_details.processing': 'calculateTotalCost',
    'unit_cost_details.packaging': 'calculateTotalCost',
    'unit_cost_details.delivery_out': 'calculateTotalCost'
  },
  async mounted() {

    if (this.product.unit_cost_details) {
      this.unit_cost_details = { ...this.product.unit_cost_details };
    }
    await this.loadProductCostData();
  },
  methods: {
    completeAction() {
      this.$emit('complete');
    },
    calculateTotalCost() {
      // Calculate the total cost by summing up all the other fields
      const {
        unit_cost,
        delivery_in,
        vat_in,
        duty_in,
        processing,
        packaging,
        delivery_out
      } = this.unit_cost_details;

      this.unit_cost_details.total_cost =
          parseFloat(unit_cost || 0) +
          parseFloat(delivery_in || 0) +
          parseFloat(vat_in || 0) +
          parseFloat(duty_in || 0) +
          parseFloat(processing || 0) +
          parseFloat(packaging || 0) +
          parseFloat(delivery_out || 0);
    },

    async saveUnitCostDetails() {
      this.isBusy = true;

      try {
        if (this.unit_cost_details.unit_cost_details_id) {
          await this.updateUnitCostDetails();
        } else {
          await this.createUnitCostDetails();
        }

        this.$emit("complete");

      } catch (error) {
        console.error('Error saving unit cost details:', error);
        this.notifyError('An error occurred while saving unit cost details.');
      } finally {
        this.isBusy = false;
      }
    },
    async createUnitCostDetails() {
      try {
        const response = await this.ProductServiceV2.createUnitCost(
            this.product.product_id,
            this.unit_cost_details
        );
        this.unit_cost_details.unit_cost_details_id = response.unit_cost_details_id; // Save the ID
        this.notifySuccess('Unit cost details created successfully.');
      } catch (error) {
        console.error('Error creating unit cost details:', error);
        const errorMessage = (error && error.data && error.data.message)
            ? error.data.message
            : 'An error occurred while creating unit cost details.';

        this.notifyError(errorMessage);
      }
    },
    async updateUnitCostDetails() {
      try {
        await this.ProductServiceV2.updateUnitCost(
            this.product.product_id,
            this.unit_cost_details
        );
        this.notifySuccess('Unit cost details updated successfully.');
      } catch (error) {
        console.error('Error updating unit cost details:', error);
        const errorMessage = (error && error.data && error.data.message)
            ? error.data.message
            : 'An error occurred while creating unit cost details.';

        this.notifyError(errorMessage);
      }
    },

    async loadProductCostData() {
      try {
        const updatedProduct = this.product;
        if (updatedProduct.unit_cost_details) {
          this.unit_cost_details = { ...updatedProduct.unit_cost_details }; // Update local unit_cost_details
        }
      } catch (error) {
        console.error('Error reloading product data:', error);
        this.notifyError('An error occurred while reloading product data.');
      }
    },
  }
};
</script>

