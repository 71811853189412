<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Supplier List
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <AppButton color="green" label="Add Supplier" @click="addNewSupplier" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="add" class="" />
        </template>
      </AppButton>
    </div>

    <!-- Supplier Table -->
    <div>
      <DataTable
          :value="suppliersList"
          dataKey="supplier_detail_id"
          :paginator="true"
          :rows="10"
          :lazy="false"
      >
        <!-- Supplier Columns -->
        <Column field="supplierName" header="Supplier" :headerStyle="{ width: '15%' }"></Column>
        <Column field="supplierPartNumber" header="Supplier Part Number" :headerStyle="{ width: '15%' }"></Column>
        <Column field="deliveryLeadTime" header="Delivery Lead Time" :headerStyle="{ width: '15%' }"></Column>
        <Column field="supplierCurrency" header="Supplier Currency" :headerStyle="{ width: '10%' }" ></Column>
        <Column field="unitPrice" header="Unit Price GBP" :headerStyle="{ width: '15%' }" headerClass="column-h-text-center" :styles="{ 'text-align': 'right' }"></Column>
        <Column header="Default" :headerStyle="{ width: '10%', textAlign: 'center' }" headerClass="column-h-text-center">
          <template #body="slotProps">
            <div class="flex justify-center">
              <InputSwitch
                  v-model="slotProps.data.isDefault"
                  disabled
              />
            </div>
          </template>
        </Column>
        <Column header="" :headerStyle="{ width: '10%', textAlign: 'center' }">
          <template #body="slotProps">
            <div class="flex justify-center space-x-2">
              <AppButton color="blue" label="" :icon="true" @click="editSupplier(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="edit" />
                </template>
              </AppButton>
              <AppButton color="red" label="" :icon="true" @click="deleteSupplier(slotProps.data)">
                <template v-slot:icon>
                  <Icon iconType="delete" />
                </template>
              </AppButton>
            </div>
          </template>
        </Column>
        <template #empty>
          <div class="w-full text-center text-gray-500 py-4">No supplier details available.</div>
        </template>
      </DataTable>

    </div>

    <!-- Add/Edit Supplier Panel -->
    <portal to="overlay-outlet">
      <panel
          :showing="addSupplierPanelOpen"
          @close="onPanelClose"
          title="Supplier Details"
      >
        <AddSupplier
            :product_id="product.product_id"
            :supplierDetailsProp="selectedSupplier"
            @complete="handleEditComplete"
        />
      </panel>
    </portal>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import AddSupplier from "@/components/Products/Supplier/AddSupplier.vue";
import Panel from "@/components/Panel.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "SupplierList",
  mixins: [notificationMixin],
  components: { Panel, AddSupplier, Icon, AppButton },
  props: {
    product: {
      type: Object,
      required: true,
    },
    suppliers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addSupplierPanelOpen: false,
      selectedSupplier: null,
    };
  },
  computed: {
    suppliersList() {
      return this.product.supplier_details.map((supplier) => {
        return {
          supplierName: supplier.supplier_info && supplier.supplier_info.name ? supplier.supplier_info.name : "N/A",
          supplierPartNumber: supplier.supplier_part_number || "N/A",
          deliveryLeadTime: supplier.delivery_lead_time || "N/A",
          supplierCurrency: supplier.currency || "N/A",
          unitPrice: supplier.unit_price ? `£${parseFloat(supplier.unit_price).toFixed(2)}` : "N/A",
          isDefault: supplier.is_default || false,
          supplierDetailId: supplier.supplier_detail_id,
        };
      });
    },
  },

  methods: {
    addNewSupplier() {
      this.addSupplierPanelOpen = true;
      this.$emit("addSupplier");
    },
    editSupplier(supplier) {
      this.selectedSupplier = this.product.supplier_details.find(
          (item) => item.supplier_detail_id === supplier.supplierDetailId
      );
      this.addSupplierPanelOpen = true;
    },
    async deleteSupplier(supplier) {
      try {

        debugger;
        // Ensure the supplier parameter is valid and has a supplier_detail_id
        if (!supplier || !supplier.supplierDetailId) {
          this.notifyError("Cannot delete: Supplier detail ID is missing.");
          return;
        }

        // Prompt the user for confirmation
        const confirmation = await this.confirmAction({
          message: `Do you want to delete the supplier "${supplier.supplierName || 'this supplier'}"?`,
          header: "Confirm Action",
          acceptLabel: "Yes",
          rejectLabel: "No",
        });

        if (!confirmation) {
          this.notifyInfo("Deletion canceled.");
          return;
        }

        // Call the service to delete the supplier detail
        await this.ProductServiceV2.deleteSupplierDetail(
            this.product.product_id,
            supplier.supplierDetailId
        );

        this.notifySuccess(`Supplier "${supplier.supplierName || 'Supplier'}" deleted successfully.`);

        // Notify parent component to refresh data
        this.$emit("complete");
      } catch (error) {
        console.error("Error deleting supplier details:", error);
        this.notifyError("An error occurred while deleting supplier details.");
      }
    },
    async handleEditComplete() {
      this.$emit("complete");
      this.onPanelClose();
    },
    formatPrice(rowData) {
      return `£${parseFloat(rowData.unit_price || 0).toFixed(2)}`;
    },
    onPanelClose() {
      this.selectedSupplier = null; // Reset selected supplier when the panel closes
      this.addSupplierPanelOpen = false;
    },
  },
};
</script>

<style scoped>
::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
