<template>
  <div
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{
      'overflow-y-hidden': viewState == 'Loading',
      'overflow-y-scroll': !viewState == 'Loading',
    }"
  >

    <page-header backTo="product-index" title="Edit Product">

      <div>
        <button
            :class="verifiedButtonClass"
            @click="openEditPanel"
            type="button"
            class="flex items-center border hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
              class="stroke-current w-5 h-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
          >
            <path
                v-if="isVerified"
                d="M5 12l5 5l10 -10"
            />
            <path
                v-else
                d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01"
            />
          </svg>
          <span>{{ verifiedButtonText }}</span>
        </button>
      </div>

<!--      <button-->
<!--          @click="createJobSheet"-->
<!--          type="button"-->
<!--          class="ml-4  flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"-->
<!--      >-->
<!--        <svg-->
<!--            class="stroke-current h-5 w-5 mr-2"-->
<!--            fill="none"-->
<!--            stroke-width="2"-->
<!--            stroke-linecap="round"-->
<!--            stroke-linejoin="round"-->
<!--            viewBox="0 0 24 24"-->
<!--        >-->
<!--          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>-->
<!--          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/>-->
<!--          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"/>-->
<!--          <path d="M9 12h6"/>-->
<!--          <path d="M9 16h6"/>-->
<!--        </svg>-->
<!--        <span>Create Job Sheet</span>-->
<!--      </button>-->

      <button
          @click="archiveProduct"
          type="button"
          class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M21 8v13H3V8M1 3h22v5H1zM10 12h4"/>
        </svg>
        <span>Archive</span>
      </button>

    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div v-if="product" class="flex flex-col">


        <div class="flex space-x-6">
          <!-- Box 1: First and Middle Section -->
          <div class="w-10/12 bg-white p-3 rounded shadow-md flex items-stretch">
            <!-- Left Panel -->
            <div class="w-1/5 flex flex-col space-y-2 pr-4">
              <!-- Product Code -->
              <div>
                <p class="text-secondary text-sm font-semibold mb-1">Product Code</p>
                <div class="flex items-center bg-orange-200 text-orange-700 rounded-lg py-1 px-2">
                  <svg
                      class="h-4 w-4 mr-2"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <path
                        d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"
                    />
                    <path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12" />
                  </svg>
                  <span class="text-sm font-semibold">{{ product.product_code }}</span>
                </div>
              </div>

              <!-- Item Code -->
              <div>
                <p class="text-secondary text-sm font-semibold mb-1">Item Code</p>
                <div class="flex items-center bg-orange-100 text-orange-700 rounded-lg py-1 px-2">
                  <svg
                      class="h-4 w-4 mr-2"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <path
                        d="M22 19a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5l2 3h9a2 2 0 012 2z"
                    />
                  </svg>
                  <span class="text-sm font-semibold">{{ product.record_number }}</span>
                </div>
              </div>

              <!-- Verified -->
              <VerifiedStatus
                  :isVerified="isVerified"
                  :date="formattedVerifiedDate"
                  iconSize="h-4 w-4"
                  paddingSize="py-1 px-2"
                  textSize="text-sm"
              />
            </div>

            <!-- Vertical Line -->
            <div class="flex items-center">
              <div class="w-px bg-orange-500 h-32"></div>
            </div>

            <!-- Middle Section -->
            <div class="flex-1 px-4">
              <!-- Title with Edit Icon -->
              <h2 class="text-xl font-bold mb-2 flex items-center">
                {{ product.name }}
                <button
                    @click="openEditPanel"
                    class="ml-3 text-secondary bg-white p-1 rounded border border-transparent hover:border-secondary active:border-secondary active:shadow-inner active:bg-gray-200 transition duration-100"
                >
                  <svg
                      class="stroke-current h-4 w-4"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                  </svg>
                </button>
              </h2>

              <!-- Description -->
              <div class="p-3 bg-gray-100 rounded-lg">
                <p class="text-gray-800 text-sm leading-relaxed">
                  {{ product.description }}
                </p>
              </div>
            </div>
          </div>

          <!-- Box 2: Right Section -->
          <div class="w-2/12 bg-white p-3 rounded shadow-md flex items-center justify-center">
            <div class="w-40 h-36 bg-red-100 rounded flex items-center justify-center">
              <template v-if="primary_image && primary_image.access_url_thumbnail">
                <img
                    :src="primary_image.access_url_thumbnail"
                    alt="Product Image"
                    class="h-full w-full object-cover rounded"
                />
              </template>
              <template v-else>
                <img
                    src="/default_product_image.png"
                    alt="Default Product Image"
                    class="h-full w-full object-cover rounded"
                />
              </template>
            </div>
          </div>
        </div>








        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="w-full mb-3 flex -mx-2 mr-2">
            <!-- Loop through tabs -->
            <button
                v-for="(tab, index) in tabs"
                :key="tab.key"
                @click="currentTab = tab.key"
                class="px-4 py-1 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
        'bg-orange-300 text-orange-900 border-orange-300': currentTab === tab.key,
        'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200': currentTab !== tab.key,
      }"
            >
              <span>{{ tab.label }}</span>
            </button>

          </div>


          <!-- Dynamic content rendering -->
          <div v-if="currentTab === 'pricing'">
            <PricingDetails :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'compatibleWith'">
            <CompatibleWithList :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'lineItems'">
            <LineItemList :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'detail'">
            <ProductDetails :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'supplier'">
            <SupplierList :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'stock'">
            <StockDetails :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'images'">
            <Images class="mt-8" :product="product" @complete="handleEditComplete"/>
          </div>
          <div v-if="currentTab === 'faqs'">
            <FAQTable :load-faqs="loadFaqs" />
          </div>
          <div v-if="currentTab === 'service-template'">
            <ServiceTemplate :product_id="product.product_id" />
          </div>
          <div v-if="currentTab === 'service-certificate'">
            <ServiceCertificate
                :product_id="product.product_id"
                :productCertificates="product.certificate_type"
            />
          </div>
          <div v-if="currentTab === 'documents'">
            <DocumentsList :product_id="product.product_id" @complete="handleEditComplete"/>
          </div>
        </div>



      </div>
      <div v-if="viewState == 'Error'">
        <div
            class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
        >
          <h2 class="text-lg font-bold">
            {{ errorMsg || "There was an error loading your product details" }}
          </h2>
          <p>
            Something went wrong loading your product, please try again. If the
            problem persists, please contact your support team.
          </p>
        </div>
      </div>
    </main>

    <portal to="overlay-outlet">
      <panel
          :showing="editDetailsPanelOpen"
          @close="editDetailsPanelOpen = false"
          title="Edit Product Details"
      >
        <edit-product-details
            :product="productDetails"
            @complete="handleEditComplete"
        />
      </panel>
    </portal>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import EditProductDetails from "@/components/Products/Edit/EditProductDetails.vue";
import AddEditTradePrice from "@/components/Products/TradePrices/AddEditTradePrice.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import Images from "@/components/Products/Edit/Images/Images.vue";
import Notification from "@/components/Notification.vue";
import AddLineItem from "@/components/Products/Create/AddLineItem.vue";
import EditLineItem from "@/components/Products/Create/EditLineItem.vue";
import ServiceTemplate from "@/components/Products/Service/Template.vue";
import PricingDetails from "@/components/Products/Price/PricingDetails.vue";
import ServiceCertificate from "@/components/Products/Service/Certificate.vue";
import FAQTable from '@/components/Faqs/FAQTable.vue';
import ProductDetails from '@/components/Products/Details/ProductDetails.vue';
import StockDetails from '@/components/Products/Stock/StockDetails.vue';
import SupplierList from '@/components/Products/Supplier/SupplierList.vue';
import LineItemList from '@/components/Products/LineItem/LineItemList.vue';
import VerifiedStatus from '@/components/Products/VerifiedStatus.vue';
import CompatibleWithList from '@/components/Products/CompatibleWith/CompatibleWithList.vue';
import DocumentsList from '@/components/Products/Documents/DocumentsList.vue';
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "EditProduct",
  mixins: [notificationMixin],
  props: {
    productCode: String,
  },
  components: {
    PageHeader,
    Spinner,
    Panel,
    EditProductDetails,
    AddEditTradePrice,
    Images,
    AddLineItem,
    EditLineItem,
    ConfirmAlert,
    FAQTable,
    ServiceTemplate,
    ServiceCertificate,
    PricingDetails,
    ProductDetails,
    StockDetails,
    SupplierList,
    VerifiedStatus,
    LineItemList,
    CompatibleWithList,
    DocumentsList,
  },
  data() {
    return {
      viewState: "Idle",
      errorMsg: null,
      product: null,
      tradePriceTitle: "",
      showingAddTradePricePanel: false,
      showingAddLineItemPanel: false,
      showingEditLineItemPanel: false,
      editDetailsPanelOpen: false,
      supplierNameSelectOpen: false,
      editingTradePrice: {},
      productDetails: {},
      lineItemToEdit: {},
      supplierSearchTerm: "",
      allSuppliers: [],
      supplierResults: [],
      primary_image: {},

      isLocalEnvironment: window.location.hostname === 'localhost',

      currentTab: "pricing",
      tabs: [
        { key: "pricing", label: "Pricing" },
        { key: "detail", label: "Product Details" },
        { key: "stock", label: "Stock Details" },
        { key: "supplier", label: "Supplier Details" },
        { key: "lineItems", label: "Bill of Materials" },
        { key: "compatibleWith", label: "Linked Products" },
        { key: "images", label: "Images" },
        { key: "faqs", label: "FAQ's" },
        { key: "service-template", label: "Service Templates" },
        { key: "service-certificate", label: "Certificates" },
        { key: "documents", label: "Documents" },
      ],

      items: [
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            this.$toast.add({severity:'success', summary:'Updated', detail:'Data Updated', life: 3000});
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'Vue Website',
          icon: 'pi pi-external-link',
          command: () => {
            window.location.href = 'https://vuejs.org/'
          }
        },
        {
          label: 'Upload',
          icon: 'pi pi-upload',
          command: () => {
            window.location.hash = "/fileupload"
          }
        }
      ]
    };
  },

  computed: {


    formattedVerifiedDate() {
      try {
        if (this.product.verified_at) {
          debugger;
          const formattedDate = this.$moment.unix(this.product.verified_at).format("DD/MM/YYYY");
          if (formattedDate === "Invalid date") {
            return "Not Available";
          }
          return formattedDate;
        }
        return "Not Available";
      } catch (error) {
        console.error("Error formatting date:", error);
        return "Not Available";
      }
    },


    isVerified() {
      if (!this.product) {
        return false;
      }

      if (!this.product.verified_at || !this.product.verification_period) {
        return false;
      }
      const verifiedDate = this.$moment.unix(this.product.verified_at);
      const verificationPeriodMonths = parseInt(this.product.verification_period, 10) || 0;
      const expiryDate = verifiedDate.add(verificationPeriodMonths, "months");
      const currentDate = this.$moment();

      return currentDate.isBefore(expiryDate); // True if current date is before expiry
    },
    verifiedButtonClass() {
      return this.isVerified
          ? "bg-green-200 hover:bg-green-300 border-green-400 hover:border-green-500"
          : "bg-red-200 hover:bg-red-300 border-red-400 hover:border-red-500";
    },
    verifiedButtonText() {
      return this.isVerified ? "Verified" : "Verify";
    },
  },
  methods: {
    tabClasses(tabKey) {
      return this.currentTab === tabKey
          ? "bg-orange-300 text-orange-900 border-orange-300"
          : "text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200";
    },
    async archiveProduct() {
      const confirmation = await this.confirmAction({
        message: "Do you want to archive this product?",
        header: "Confirm Action",
        acceptLabel: "Yes",
        rejectLabel: "No",
      });

      if (confirmation) {
        try {
          if (this.product.product_id) {
            await this.ProductServiceV2.archiveProduct(this.product.product_id);
            this.notifySuccess("Product archived successfully.");
            this.$router.go(-1);

          } else {
            this.notifyError("Product ID is missing.");
          }
        } catch (error) {
          this.notifyError("Couldn't archive the product.");
          console.error("Error archiving product:", error);
        }
      }
    },
    async fetchPrimaryImage() {
      try {

        const files = await this.ProductService.getProductDocuments(
            this.product.product_id,  // Product ID
            'attachment',      // Document role
            true               // is_default = true
        );

        if (files && files.length > 0) {

          this.primary_image = files[0];
          console.log("Primary image assigned:", this.primary_image);
        } else {
          console.warn("No primary image found with is_default=true.");
        }
      } catch (error) {
        console.error("Error fetching primary image:", error);
        this.notifyError("Failed to fetch the primary image.");
      }
    },
    // createJobSheet() {
    //   if (this.productCode) {
    //     this.$router.push({
    //       name: "engineer-job-sheet-create",
    //       query: {
    //         product_code: this.productCode
    //       },
    //     });
    //   }
    // },
    async loadFaqs(pageOrderSettings) {
      try {
        return await this.faqService.getFAQsByProductCode(
            pageOrderSettings,
            this.productCode
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    openEditPanel: function () {
      this.productDetails.name = this.product.name;
      this.productDetails.product_id = this.product.product_id;
      this.productDetails.description = this.product.description;
      this.productDetails.product_code = this.product.product_code;
      this.productDetails.verified_at = this.product.verified_at;
      this.productDetails.verification_period = this.product.verification_period;
      this.editDetailsPanelOpen = true;
    },
    async fetchAndInitializeProduct() {
      try {
        const productCode=this.productCode
        const product = await this.ProductServiceV2.getProductByCode(productCode);

        // Ensure nested objects exist
        product.location = product.location || {};
        product.supplier_info = product.supplier_info || {};

        return product;
      } catch (error) {
        console.error("Error fetching product:", error);
        this.notifyError("Failed to fetch product information.");
        throw error; // Re-throw the error if necessary
      }
    },
    async initializeProductDetails() {
      this.viewState = "Loading";
      try {
        this.product = await this.fetchAndInitializeProduct();
        await this.fetchPrimaryImage();
        this.viewState = "Idle";
      } catch (error) {
        if (error.status === 404) {
          this.errorMsg = `Cannot find Product with code ${this.productCode}`;
        }
        console.error(error);
        this.viewState = "Error";
      }
    },
    async handleEditComplete() {
      await this.initializeProductDetails();
      this.editDetailsPanelOpen = false;
    },
  },
  async mounted() {
    await this.initializeProductDetails();
  },
};
</script>
