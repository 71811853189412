<template>
  <form @submit.prevent="saveImage" class="flex flex-wrap">
    <div v-if="isBusy" class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
      <ProgressSpinner  style="width:50px;height:50px"/>
    </div>

    <p>Schedule or record an event for this enquiry</p>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Image Name:</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="name"
        placeholder="Image Name..."
        type="text"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Image Description:</label>
      <textarea
        v-model="description"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Image Description..."
        rows="10"
      ></textarea>
    </div>
    <div v-if="status == 'Create'" class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Image:</label>
      <div
        @drop.prevent="onDropFiles"
        @dragover.prevent
        @change="onFilesChange"
        class="relative w-full bge-input rounded"
      >
        <input
          multiple
          class="absolute w-full h-full opacity-0 cursor-pointer"
          type="file"
          accept="image/*"
        />
        <div class="flex items-center py-2 px-4">
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
            <path d="M14 2v6h6M12 18v-6M9 15h6" />
          </svg>
          <p>Browse Images or Drop Here</p>
          <!-- <p v-if="image.file">{{ image.file.name }}</p> -->
        </div>
      </div>
    </div>

<!--    <div class="flex w-full">-->
<!--      <div class="w-1/2 pr-2 mb-4">-->
<!--        <label class="block uppercase font-semibold text-sm mb-1">Set as Default</label>-->
<!--        <div class="flex items-center">-->
<!--          <InputSwitch v-model="is_default" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div v-if="status == 'Create'" class="w-full py-2">
      <template v-for="(image, i) in tempImages">
        <div :key="i">{{ image.file.name }}</div>
      </template>
    </div>

    <div class="w-full">
      <button
        type="submit"
        class="float-right mt-5 rounded border bg-green-200 border-green-400 text-green-800 py-1 px-3 hover:bg-green-300 hover:border-green-500 hover:shadow"
      >{{ status == 'Create' ? 'Upload Images' : 'Save Image' }}</button>
    </div>

    <div v-if="uploadProgress > 0" class="w-full py-4">
      <label class="uppercase font-semibold text-sm">Upload Progress</label>
      <div class="w-full bg-gray-200 rounded-full h-4">
        <ProgressBar :value="uploadProgress" />
      </div>
    </div>

  </form>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";
import Pica from "pica";

export default {
  mixins: [notificationMixin],
  name: "AddEditImage",
  components: {},
  props: {
    images: {
      required: true,
      type: Array
    },
    uploadRefId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      status: "",
      name: "",
      description: "",
      document_id: "",
      tempImages: [],
      uploadProgress: 0,
      isBusy: false,
    };
  },
  mounted() {
    this.tempImages = this.images;
    if (this.tempImages.length == 1) {
      this.status = "Edit";
      this.name = this.tempImages[0].name;
      this.description = this.tempImages[0].description;
      this.document_id = this.tempImages[0].document_id;
    } else {
      this.status = "Create";
    }
  },
  methods: {
    onDropFiles: function(e) {
      if (this.status == "Create") {
        this.handleAttachments(e.dataTransfer.files);
      }
    },
    onFilesChange: function(e) {
      if (this.status == "Create") {
        this.handleAttachments(e.target.files);
      }
    },
    handleAttachments: function(fileList) {
      debugger;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const filename= file.name;
        const name= this.name;
        const description= this.description;
        const fileUrl = URL.createObjectURL(file);
        const fileType = this.getFileExtension(file);
        const uniqueId = this.generateUniqueId();
        const requires_thumbnail = this.isImageType(fileType);

        this.tempImages.push({
          access_url: fileUrl,
          access_url_thumbnail: fileUrl,
          filename: file.name,
          original_filename: filename,
          name: name,
          description: description,
          isNew: true,
          content_type: fileType,
          unique_id: uniqueId,
          original_file: file,
          file: file,
          requires_thumbnail,
          progress: 0,
        });
      }
    },

    saveImage: async function() {

      if (!this.name.trim()) {
        this.notifyError("Image Name is required.");
        return false;
      }

      if (!this.description.trim()) {
        this.notifyError("Image Description is required.");
        return false;
      }

      console.log("Uploading or updating documents", this.tempImages);

      this.isBusy = true; // Start showing the spinner
      try {
        // Check if edit mode is active
        if (this.status === "Edit") {
          const editingDoc = {
            name: this.name,
            description: this.description,
            document_id: this.document_id,
          };

          if (!this.uploadRefId) {
            this.notifyError("Please save the details before updating the document.");
            return;
          }

          console.log("Updating document:", editingDoc);

          // Call update function
          const updatedDocument = await this.ProductService.updateDocument(
              this.document_id, // Document ID
              this.uploadRefId, // Product ID
              editingDoc        // Update payload
          );

          this.notifySuccess("Document updated successfully.");
          console.log("Updated Document:", updatedDocument);
        } else {
          // If not in edit mode, filter new files for upload
          const newFilesToUpload = this.tempImages.filter((file) => file.isNew);

          if (!newFilesToUpload || newFilesToUpload.length === 0) {
            this.notifyWarning("No new files to upload.");
            return;
          }

          console.log("Uploading new files with thumbnails:", newFilesToUpload);

          // Call upload function for new files with thumbnails
          await this.uploadDocumentWithThumbnail(newFilesToUpload);
          this.notifySuccess("Documents and thumbnails uploaded successfully.");
        }
      } catch (error) {
        console.error("Error during saveImage:", error);
        this.notifyError("An error occurred during the operation.");
      } finally {
        this.isBusy = false;
        this.$emit('complete');
      }
    },


    async uploadDocumentWithThumbnail(editingDoc) {
      this.isBusy = true;
      try {
        if (!this.uploadRefId) {
          this.notifyError("Please save the details before uploading documents.");
          return;
        }
        debugger;

        const uploadResults = await this.ProductService.uploadDocuments(
            editingDoc,
            this.uploadRefId
        );

        for (const uploadResult of uploadResults) {
          const fileToUpload = this.tempImages.find(
              (file) => file.unique_id === uploadResult.unique_id
          );
          if (fileToUpload) {
            // Upload the main document
            if (uploadResult.access_url) {
              await this.uploadFileToBlob(
                  uploadResult.access_url,
                  fileToUpload.original_file
              );
            }

            // Upload the thumbnail
            if (fileToUpload && uploadResult.access_url_thumbnail) {
              const thumbnailFile = await this.resizeImage(
                  fileToUpload.original_file
              );

              await this.uploadFileToBlob(
                  uploadResult.access_url_thumbnail,
                  thumbnailFile
              );
            }
          }
        }


      } catch (error) {
        console.error("Upload error:", error);
        this.notifyError("There was an error uploading the document.");
      } finally {
        this.isBusy = false;
      }
    },
    async resizeImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Define maximum dimensions for the thumbnail
            const maxWidth = 300;
            const maxHeight = 225;

            // Maintain aspect ratio
            const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;

            // Draw image onto canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas to Blob and create a File
            canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const resizedFile = new File([blob], `resized_${file.name}`, {
                      type: "image/jpeg",
                      lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                  } else {
                    reject(new Error("Canvas to Blob conversion failed."));
                  }
                },
                "image/jpeg",
                0.8 // Quality parameter
            );
          };
          img.onerror = () => reject(new Error("Image load error."));
          img.src = e.target.result;
        };
        reader.onerror = () => reject(new Error("FileReader error."));
        reader.readAsDataURL(file);
      });
    },


    async uploadFileToBlob(sasUrl, file) {
      const url = new URL(sasUrl);
      const accountUrl = url.origin;
      const sasToken = url.search;
      const pathParts = url.pathname.split("/").filter((p) => p.trim() !== "");
      const container = pathParts[0];
      const filename = pathParts.slice(1).join("/");

      const blobService = AzureStorage.Blob.createBlobServiceWithSas(
          accountUrl,
          sasToken
      );
      const customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 128;
      blobService.singleBlobPutThresholdInBytes = customBlockSize;

      return new Promise((resolve, reject) => {
        const speedSummary = blobService.createBlockBlobFromBrowserFile(
            container,
            filename,
            file,
            { blockSize: customBlockSize },
            (error) => {
              if (error) {
                reject(error);
              } else {
                this.uploadProgress = 100; // Ensure progress is set to 100% on completion
                resolve();
              }
            }
        );

        speedSummary.on("progress", () => {
          const progress =
              Math.round((speedSummary.getCompletePercent() || 0) * 100) / 100;
          this.uploadProgress = progress; // Update progress
        });
      });
    },

    isImageType(file) {
      return ["jpg", "jpeg", "png", "gif", "bmp"].includes(
          file.toLowerCase()
      );
    },

    getFileExtension(file) {
      return file.name
          ? file.name.split(".").pop()
          : file.access_url.split(".").pop();
    },

    resetFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },

    generateUniqueId() {
      return `id_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
    },
  }
};
</script>