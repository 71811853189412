<template>
  <div class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Product</label>
      <div>
        <ProductAutoComplete
            class="w-full"
            v-model="selectedProduct"
            @productSelected="handleProductSelected"
            @productCleared="handleProductCleared"
        />
      </div>
    </div>



    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Product Code</label>
      <input
          v-model="lineItemDetails.product_code"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          required
          placeholder="Product Code..."
          readonly
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Quantity</label>
      <input
          v-model="lineItemDetails.qty"
          class="w-full bge-input bge-input-spacing rounded"
          type="number"
          required
          placeholder="Quantity"
          min="1"
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <textarea
          v-model="lineItemDetails.description"
          class="w-full bge-input bge-input-spacing rounded"
          rows="4"
          placeholder="Description..."
          readonly
      ></textarea>
    </div>

    <!-- Toggles for Packing List, Dispatch Note, etc. -->
    <div class="grid grid-cols-2 gap-4 w-full">
      <!-- Packing List -->
      <div class="flex items-center bg-gray-100 p-4 rounded-lg shadow-md">
        <div class="flex items-center justify-center w-10 h-10 bg-orange-200 text-orange-700 rounded-full mr-4">
          <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
          >
            <path d="M8 21h8m-4-4v4M5 8h14M9 3h6M5 8v8h14V8" />
          </svg>
        </div>
        <div class="flex-1">
          <label class="block uppercase font-semibold text-sm mb-1">Packing List</label>
          <InputSwitch v-model="lineItemDetails.packing_list" />
        </div>
      </div>

      <!-- Dispatch Note -->
      <div class="flex items-center bg-gray-100 p-4 rounded-lg shadow-md">
        <div class="flex items-center justify-center w-10 h-10 bg-blue-200 text-blue-700 rounded-full mr-4">
          <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
          >
            <path d="M3 10h18M3 14h18M7 6h10M7 18h10" />
          </svg>
        </div>
        <div class="flex-1">
          <label class="block uppercase font-semibold text-sm mb-1">Dispatch Note</label>
          <InputSwitch v-model="lineItemDetails.dispatch_note" />
        </div>
      </div>

      <!-- Order Confirmation -->
      <div class="flex items-center bg-gray-100 p-4 rounded-lg shadow-md">
        <div class="flex items-center justify-center w-10 h-10 bg-green-200 text-green-700 rounded-full mr-4">
          <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
          >
            <path d="M5 12l5 5L20 7" />
          </svg>
        </div>
        <div class="flex-1">
          <label class="block uppercase font-semibold text-sm mb-1">Order Confirmation</label>
          <InputSwitch v-model="lineItemDetails.order_confirmation" />
        </div>
      </div>

      <!-- Invoice -->
      <div class="flex items-center bg-gray-100 p-4 rounded-lg shadow-md">
        <div class="flex items-center justify-center w-10 h-10 bg-red-200 text-red-700 rounded-full mr-4">
          <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
          >
            <path d="M12 8V4M12 20v-4M4 12h16" />
          </svg>
        </div>
        <div class="flex-1">
          <label class="block uppercase font-semibold text-sm mb-1">Invoice</label>
          <InputSwitch v-model="lineItemDetails.invoice" />
        </div>
      </div>
    </div>

    <!-- Save Button -->
    <div class="flex w-full justify-end mt-4">
      <AppButton color="green" label="Save BOM Item" @click="saveLineItemDetails" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="save" class="mr-2" />
        </template>
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import ProductAutoComplete from "@/components/AutoComplete/ProductAutoComplete.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "AddLineItem",
  mixins: [notificationMixin],
  components: { Icon, AppButton,  ProductAutoComplete },
  props: {
    lineItemDetailsProp: {
      type: Object,
      required: false,
      default: () => null,
    },
    product_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lineItemDetails: {
        name: "",
        product_code: "",
        product_id: "",
        qty: 1,
        description: "",
        packing_list: false,
        dispatch_note: false,
        order_confirmation: false,
        invoice: false,
      },
      selectedProduct: null,
    };
  },
  async mounted() {
    if (this.lineItemDetailsProp) {
      this.lineItemDetails = { ...this.lineItemDetailsProp };
      this.selectedProduct={
        name: this.lineItemDetails.name,
        product_code: this.lineItemDetails.product_code,
        product_id: this.lineItemDetails.product_id,
      }
    }
  },
  methods: {
    handleProductSelected(selectedProduct) {
      debugger;
      if (selectedProduct && selectedProduct.value) {
        this.lineItemDetails.product_id = selectedProduct.value.product_id || "";
        this.lineItemDetails.product_code = selectedProduct.value.product_code || "";
        this.lineItemDetails.name = selectedProduct.value.name || "";
        this.lineItemDetails.description = selectedProduct.value.description || "";
      } else {
        // If metadata is missing, reset the fields
        this.handleProductCleared();
      }
    },
    handleProductCleared() {
      this.lineItemDetails.product_id = "";
      this.lineItemDetails.product_code = "";
      this.lineItemDetails.name = "";
      this.lineItemDetails.description = ""; // Reset description as well
    },

    async saveLineItemDetails() {
      try {
        if (!this.lineItemDetails.name || !this.lineItemDetails.product_code) {
          this.notifyError("Please select a product.");
          return;
        }

        if (this.lineItemDetails.line_item_id) {
          // Update existing line item
          await this.ProductServiceV2.updateLineItem(
              this.product_id,
              this.lineItemDetails.line_item_id,
              this.lineItemDetails
          );
          this.notifySuccess("Line item updated successfully.");
        } else {
          // Create new line item
          const createdLineItem = await this.ProductServiceV2.createLineItem(
              this.product_id,
              this.lineItemDetails
          );
          this.lineItemDetails.line_item_id = createdLineItem.line_item_id;
          this.notifySuccess("Line item created successfully.");
        }

        // Notify parent component to refresh data
        this.$emit("complete");
      } catch (error) {
        console.error("Error saving line item details:", error);
        this.notifyError("An error occurred while saving line item details.");
      }
    },
  },
};
</script>
