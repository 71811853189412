<template>
  <form @submit.prevent="saveProductDetails" class="flex flex-wrap py-4">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Product Code</label>
      <input
        v-model="localProductDetails.product_code"
        class="w-full bge-input bge-input-spacing rounded"
        type="text"
        required
        disabled
        placeholder="Product Code..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
        v-model="localProductDetails.name"
        class="w-full bge-input bge-input-spacing rounded"
        type="text"
        required
        placeholder="Product Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <textarea
        v-model="localProductDetails.description"
        placeholder="Product Description..."
        class="w-full bge-input bge-input-spacing rounded"
        rows="10"
      ></textarea>
    </div>
    <div class="flex w-full">
      <!-- Supplier Part Number -->
      <div class="w-1/2 pr-2 mb-4">
        <label class="block uppercase font-semibold text-sm mb-1">Set Verified Date</label>
        <Calendar
            v-model="localProductDetails.verified_date"
            dateFormat="dd/mm/yy"
            :showIcon="true"
        />
      </div>

      <!-- Verification Period -->
      <div class="w-1/2 pl-2  mb-4">
        <label class="block uppercase font-semibold text-sm mb-1">Set Verification Period</label>
        <select
            v-model="localProductDetails.verification_period"
            class="w-full bge-input bge-input-spacing rounded"
        >
          <option
              v-for="time in verificationPeriods"
              :key="time.data_master_id"
              :value="time.value"
          >
            {{ time.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="w-full">
      <button
        type="button"
        @click="saveProductDetails"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Product Details</span>
      </button>
    </div>
  </form>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  props: {
    product: {
      required: true,
      type: Object
    }
  },
  data(){
    return {
      localProductDetails: {
        ...this.product,
        verified_date: this.product.verified_at
            ? this.formatTimestamp(this.product.verified_at)
            : null,
      }, // Clone product prop with formatted verified_date
      verificationPeriods: [],
    };
  },

  async mounted() {
    await this.fetchVerificationPeriods();
  },

  methods: {
    async saveProductDetails() {
      try {
        if (this.localProductDetails.verified_date) {
          this.localProductDetails.verified_at = this.$moment(
              this.localProductDetails.verified_date,
              "DD-MM-YYYY"
          ).unix(); // Convert date back to Unix
        }else {
          this.localProductDetails.verified_at=null;
        }



        // API Call
        await this.ProductServiceV2.updateProduct(
            this.localProductDetails.product_id,
            this.localProductDetails
        );
        this.$emit("complete");
        this.notifySuccess("Product details updated successfully.");

      } catch (error) {
        console.error("Error updating product details:", error);
        this.notifyError("Failed to update product details.");
      }
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format("DD-MM-YYYY");
    },
    async fetchVerificationPeriods() {
      try {
        const response = await this.DatamasterService.getDataMasters(
            "?type=VerifiedPeriod&is_active=true&noPaging=true&orderBy=order&order=1"
        );
        this.verificationPeriods = this.mapDropdownOptions(response);
      } catch (error) {
        console.error("Error fetching delivery times:", error);
      }
    },
    mapDropdownOptions(response) {
      return response.map((item) => ({
        data_master_id: item.data_master_id,
        name: item.name,
        value: item.value || "",
      }));
    },
  }
};
</script>