<template>
  <form @submit.prevent="createProduct" class="flex flex-wrap py-4">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Product Code</label>
      <input
          v-model="newProduct.product_code"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          required
          placeholder="Product Code..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
          v-model="newProduct.name"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          required
          placeholder="Product Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <textarea
          v-model="newProduct.description"
          placeholder="Product Description..."
          class="w-full bge-input bge-input-spacing rounded"
          rows="5"
      ></textarea>
    </div>
    <div class="w-full">
      <button
          type="button"
          @click="createProduct"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Create Product</span>
      </button>
    </div>
  </form>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  data() {
    return {
      newProduct: {
        product_code: "",
        name: "",
        description: "",
      },
    };
  },
  methods: {
    async createProduct() {
      try {
        // Validate required fields
        if (!this.newProduct.product_code || !this.newProduct.name) {
          this.notifyError("Product Code and Name are required.");
          return;
        }

        // API call to create the product
        const createdProduct = await this.ProductServiceV2.createProduct(this.newProduct);

        // Notify success and redirect
        this.notifySuccess("Product created successfully.");
        await this.$router.push({
          name: "product-edit",
          params: { productCode: createdProduct.product_code },
        });
      } catch (err) { // Corrected variable name here
        console.error("Error creating product:", err);

        // Check if it's a duplicate key error
        if (err.response && err.response.data && err.response.data.message) {
          const errorMessage = err.response.data.message;

          // Show a short message if it's a duplicate key error
          if (errorMessage.includes("already exists")) {
            this.notifyError("Duplicate Product Code. Please use a unique value.");
            return;
          }
        }

        // Fallback for other errors
        this.notifyError("Failed to create product.");
      }
    }

  },
};
</script>
