<template>
  <div class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Product</label>
      <div>
        <ProductAutoComplete
            class="w-full"
            v-model="selectedProduct"
            :forceSelection="true"
            @productSelected="handleProductSelected"
            @productCleared="handleProductCleared"
        />
      </div>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Product Code</label>
      <input
          v-model="compatibleWithDetails.product_code"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          readonly
          placeholder="Product Code..."
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
          v-model="compatibleWithDetails.name"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          readonly
          placeholder="Product Name..."
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <textarea
          v-model="compatibleWithDetails.description"
          class="w-full bge-input bge-input-spacing rounded"
          rows="4"
          readonly
          placeholder="Product Description..."
      ></textarea>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Category</label>
      <input
          v-model="compatibleWithDetails.category"
          class="w-full bge-input bge-input-spacing rounded"
          type="text"
          readonly
          placeholder="Product Category..."
      />
    </div>

    <!-- Save Button -->
    <div class="flex w-full justify-end mt-4">
      <AppButton color="green" label="Save Compatibility" @click="saveCompatibleWithDetails" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="save" class="mr-2" />
        </template>
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import ProductAutoComplete from "@/components/AutoComplete/ProductAutoComplete.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  name: "AddCompatibleWith",
  mixins: [notificationMixin],
  components: { Icon, AppButton, ProductAutoComplete },
  props: {
    compatibleWithDetailsProp: {
      type: Object,
      required: false,
      default: () => null,
    },
    product_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      compatibleWithDetails: {
        product_code: "",
        name: "",
        product_id: "",
        description: "",
        category: "",
      },
      selectedProduct: null,
    };
  },
  async mounted() {
    if (this.compatibleWithDetailsProp) {
      this.compatibleWithDetails = { ...this.compatibleWithDetailsProp };
      this.selectedProduct = {
        name: this.compatibleWithDetails.name,
        product_code: this.compatibleWithDetails.product_code,
        product_id: this.compatibleWithDetails.product_id,
        description: this.compatibleWithDetails.description,
        category: this.compatibleWithDetails.category,
      };
    }
  },
  methods: {
    handleProductSelected(selectedProduct) {
      if (selectedProduct && selectedProduct.value) {
        this.compatibleWithDetails.product_id = selectedProduct.value.product_id || "";
        this.compatibleWithDetails.product_code = selectedProduct.value.product_code || "";
        this.compatibleWithDetails.name = selectedProduct.value.name || "";
        this.compatibleWithDetails.description = selectedProduct.value.description || "";
        this.compatibleWithDetails.category = selectedProduct.value.category || "";
      } else {
        this.handleProductCleared();
      }
    },
    handleProductCleared() {
      this.compatibleWithDetails.product_id = "";
      this.compatibleWithDetails.product_code = "";
      this.compatibleWithDetails.name = "";
      this.compatibleWithDetails.description = "";
      this.compatibleWithDetails.category = "";
    },
    async saveCompatibleWithDetails() {
      try {
        if (!this.compatibleWithDetails.name || !this.compatibleWithDetails.product_code) {
          this.notifyError("Please select a product.");
          return;
        }

        if (this.compatibleWithDetails.compatible_with_id) {
          // Update existing compatible product
          await this.ProductServiceV2.updateCompatibleWith(
              this.product_id,
              this.compatibleWithDetails.compatible_with_id,
              this.compatibleWithDetails
          );
          this.notifySuccess("Compatible product updated successfully.");
        } else {
          // Create new compatible product
          const createdCompatible = await this.ProductServiceV2.createCompatibleWith(
              this.product_id,
              this.compatibleWithDetails
          );
          this.compatibleWithDetails.compatible_with_id = createdCompatible.compatible_with_id;
          this.notifySuccess("Compatible product added successfully.");
        }

        this.$emit("complete");
      } catch (error) {
        console.error("Error saving compatible product details:", error);
        this.notifyError("An error occurred while saving compatible product details.");
      }
    },
  },
};
</script>
