<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Documents
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <AppButton color="green" label="Add Document" @click="addNewDocument" :icon="true" class="mr-2">
        <template v-slot:icon>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4v16m8-8H4"
            />
          </svg>
        </template>
      </AppButton>
    </div>

    <!-- Document Table -->
    <div>
      <DataTable
          :value="documents"
          dataKey="document_id"
          :paginator="true"
          :rows="10"
          :lazy="false"
      >
        <!-- Document Columns -->
        <Column field="name" header="Name" :headerStyle="{ width: '20%' }"></Column>
        <Column field="original_filename" header="Filename" :headerStyle="{ width: '30%' }"></Column>
        <Column field="description" header="Description" :headerStyle="{ width: '40%' }"></Column>
        <Column header="" :headerStyle="{ width: '10%' }">
          <template #body="slotProps">
            <div class="flex justify-center space-x-2">
              <!-- View Document Button -->
              <AppButton
                  color="orange"
                  label=""
                  :icon="true"
                  @click="viewDoc(slotProps.data)"
              >
                <template v-slot:icon>
                  <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      viewBox="0 0 24 24"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <path d="M21 21l-4.35-4.35" />
                  </svg>
                </template>
              </AppButton>

              <!-- Download Document Button -->
              <AppButton
                  color="green"
                  label=""
                  :icon="true"
                  @click="downloadDoc(slotProps.data)"
              >
                <template v-slot:icon>
                  <svg
                      class="stroke-current h-5 w-5"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                  >
                    <path
                        d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"
                    />
                  </svg>
                </template>
              </AppButton>
              <!-- Delete Document Button -->
              <AppButton
                  color="red"
                  label=""
                  :icon="true"
                  @click="detachDocument(slotProps.data)"
              >
                <template v-slot:icon>
                  <Icon iconType="circle-minus" />
                </template>
              </AppButton>
            </div>
          </template>
        </Column>
        <template #empty>
          <div class="w-full text-center text-gray-500 py-4">
            No documents available.
          </div>
        </template>
      </DataTable>
    </div>

    <!-- Add Document Panel -->
    <portal to="overlay-outlet">
      <panel
          :showing="addDocumentPanelOpen"
          @close="onPanelClose"
          title="Add Document"
      >
        <AddDocument :product_id="product_id"  @complete="handleEditComplete"/>
      </panel>
    </portal>

    <!-- View Document Panel -->
    <portal to="overlay-outlet">
      <ViewDocument
          :showing="viewingDocument.panelOpen"
          :document="viewingDocument.doc"
          @close="viewingDocument.panelOpen = false"
      />
    </portal>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import ViewDocument from "@/components/ViewDocument.vue";
import Panel from "@/components/Panel.vue";
import AddDocument from "@/components/Products/Documents/AddDocument.vue";
import notificationMixin from "@/mixins/notificationMixin";
import AddCompatibleWith from "@/components/Products/CompatibleWith/AddCompatibleWith.vue";

export default {
  name: "DocumentList",
  mixins: [notificationMixin],
  components: {AddCompatibleWith, AppButton, Icon, ViewDocument, Panel, AddDocument},
  props: {
    product_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documents: [],
      addDocumentPanelOpen: false,
      viewingDocument: {
        doc: null,
        panelOpen: false,
      },
    };
  },
  async mounted() {
    await this.fetchDocuments();
  },
  methods: {
    async fetchDocuments() {
      try {
        const response = await this.DocumentService.getDocuments({
          resource_type: "product", // Resource type
          resource_id: this.product_id, // Resource ID
          page: 1,
          pageSize: 10, // Paging options
        });

        this.documents = response.data || [];
      } catch (error) {
        console.error("Error fetching documents:", error);
        this.notifyError("Unable to fetch documents.");
      }
    },
    addNewDocument() {
      this.addDocumentPanelOpen = true;
    },
    onPanelClose() {
      this.addDocumentPanelOpen = false;
    },
    async viewDoc(document) {
      try {
        const doc = await this.DocumentService.getDocumentById(
            document.document_id
        );
        this.viewingDocument = {
          doc: doc,
          panelOpen: true,
        };
      } catch (error) {
        console.error("Error viewing document:", error);
        this.notifyError("Unable to open document.");
      }
    },
    downloadDoc: async function (document) {
      try {
        const doc = await this.DocumentService.getDocumentById(
            document.document_id
        );
        window.open(doc.access_url);
        console.log(doc);
      } catch (error) {
        console.log(error);
      }
    },
    async detachDocument(document) {
      try {
        const confirmation = await this.confirmAction({
          message: `Do you want to detach the document "${document.name || 'this document'}"?`,
          header: "Confirm Action",
          acceptLabel: "Yes",
          rejectLabel: "No",
        });

        if (confirmation) {
          const resourceTags = document.resource_tags || []; // Ensure resource_tags is not null or undefined
          const resource = resourceTags.find((e) => e.resource_id === this.product_id);

          if (!resource) {
            this.notifyError("Resource not found for the document.");
            return;
          }

          await this.DocumentService.deleteResource(
              document.document_id,
              resource.tag_id,
              resource
          );
          this.notifySuccess("Document detached successfully.");
          await this.fetchDocuments(); // Refresh the list
        }
      } catch (error) {
        console.error("Error detaching document:", error);
        this.notifyError("Unable to detach document.");
      }
    },

    async handleEditComplete() {
      this.onPanelClose();
      await this.fetchDocuments();
    },

  },
};
</script>

<style scoped>
</style>
