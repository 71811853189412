<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Products">
      <!-- Button to open the Create Product panel -->
      <AppButton color="green" label="Create New Product" @click="openCreateProductPanel" :icon="true" class="mr-2">
        <template v-slot:icon>
          <Icon iconType="add" />
        </template>
      </AppButton>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="w-full flex items-center justify-between mb-4">
          <div class="flex -mx-0 mr-2 w-1/2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected" />
          </div>
          <div class="flex justify-end w-1/2">
            <input
                type="text"
                class="ml-6 w-6/12 rounded-l bge-input bge-input-spacing"
                placeholder="Search"
                v-model="searchTextInput"
                @keydown.enter="triggerSearch"
            />
            <button
                @click="triggerSearch"
                class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <Icon iconType="search" />
            </button>
          </div>
        </div>


        <!-- Tab Content -->
        <TabContent :currentTab="currentTab">
          <template v-slot:[currentTab]>
            <DataTableComponent
                :columns="product_columns"
                @row-click="onRowClick"
                :key="currentTab"
                :rowClass="rowClass"

            >
              <template v-slot:action-slot="{ rowData }">
                <div class="flex items-center space-x-2 ml-4">
                  <AppButton
                      color="blue"
                      label=""
                      :icon="true"
                      @click.native.stop="editProduct(rowData)"
                  >
                    <template v-slot:icon>
                      <Icon iconType="edit" />
                    </template>
                  </AppButton>
                  <AppButton
                      color="red"
                      label=""
                      :icon="true"
                      @click.native.stop="archiveProduct(rowData.product_id)"
                  >
                    <template v-slot:icon>
                      <Icon iconType="archive" />
                    </template>
                  </AppButton>
                </div>
              </template>
              <template v-slot:is-verified-slot="{ rowData }">
                <div class="flex justify-center items-center h-full">
                  <!-- Verified Icon -->
                  <span v-if="isVerified(rowData)" class="text-green-500">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="28" height="28" stroke-width="2">
        <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
        <path d="M9 12l2 2l4 -4"></path>
      </svg>
    </span>

                  <!-- Not Verified Icon -->
                  <span v-else class="text-red-500">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="28" height="28" stroke-width="2">
        <path d="M9 12l2 2l1.5 -1.5m2 -2l.5 -.5"></path>
        <path d="M8.887 4.89a2.2 2.2 0 0 0 .863 -.53l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.528 .858m-.757 3.248a2.193 2.193 0 0 1 -1.555 .644h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1c0 -.604 .244 -1.152 .638 -1.55"></path>
        <path d="M3 3l18 18"></path>
      </svg>
    </span>
                </div>
              </template>


            </DataTableComponent>

          </template>
        </TabContent>
      </div>
    </div>

    <!-- Create Product Panel -->
    <portal to="overlay-outlet">
      <panel :showing="createProductPanelOpen" @close="closeCreateProductPanel" title="Create New Product">
        <AddProduct />
      </panel>
    </portal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/Products/Components/DataTableComponent.vue";
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
import Panel from "@/components/Panel.vue";
import AddProduct from "@/components/Products/AddProduct.vue";
import { mapActions, mapState } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import VerifiedStatus from "@/components/Products/VerifiedStatus.vue";

export default {
  mixins: [notificationMixin],
  components: {
    VerifiedStatus,
    PageHeader,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
    AppButton,
    Panel,
    AddProduct,
  },
  data() {
    return {
      tabs: [
        { id: "products", label: "All Products" },
        { id: "archive", label: "Archived" },
        { id: "search", label: "Search Results" },
      ],
      product_columns: [
        {
          field: "record_number",
          header: "Item Code",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "product_code",
          header: "Product Code",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "name",
          header: "Name",
          sortable: true,
          custom: false,
          width: '25%',
        },
        {
          field: "category",
          header: "Category",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "type",
          header: "Type",
          sortable: true,
          custom: false,
          width: '22%',
        },

        {
          field: "is_verified",
          header: "Verified",
          sortable: true,
          custom: true,
          slotName: "is-verified-slot",
          width: '9%',
          align:'center',
          headerClass:"column-h-text-center",
        },

        {
          field: "action",
          header: "",
          sortable: false,
          custom: true,
          slotName: "action-slot",
          width: '4%',
        },
      ],
      createProductPanelOpen: false,
    };
  },
  computed: {
    ...mapState("product", ["currentTab", "searchText"]),
    searchTextInput: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.$store.commit("product/UPDATE_SEARCH_TEXT", value);
      },
    },
  },
  mounted() {
    this.fetchTableData(); // Refresh data on page load
  },
  methods: {
    ...mapActions("product", ["fetchTableData", "updateTab"]),
    triggerSearch() {
      this.updateTab("search");
      this.fetchTableData();
    },
    onTabSelected(newTab) {
      this.updateTab(newTab);
    },
    onRowClick(event) {
      let productCode = event.data.product_code.toLowerCase();
      this.$store.dispatch(
          "setLastSelectedProduct",
          productCode
      );
      this.$router.push({
        name: "product-edit",
        params: { productCode: productCode }
      });
    },
    rowClass(rowData) {
      let classes = [];

      if (
          rowData.product_code.toLowerCase() === this.$store.state.lastSelectedProduct.toLowerCase()
      ) {
        classes.push("highlight-row");
      }


      return classes.join(" ");
    },

    editProduct(product) {
      let productCode = product.product_code.toLowerCase();
      this.$store.dispatch(
          "setLastSelectedProduct",
          productCode
      );
      this.$router.push({
        name: "product-edit",
        params: { productCode: productCode }
      });
    },
    async archiveProduct(productId) {
      const confirmation = await this.confirmAction({
        message: "Do you want to archive this product?",
        header: "Confirm Action",
        acceptLabel: "Yes",
        rejectLabel: "No",
      });

      if (confirmation) {
        try {
          if (productId) {
            await this.ProductServiceV2.archiveProduct(productId);
            this.notifySuccess("Product archived successfully.");
            await this.fetchTableData();
          } else {
            this.notifyError("Product ID is missing.");
          }
        } catch (error) {
          this.notifyError("Couldn't archive the product.");
          console.error("Error archiving product:", error);
        }
      }
    },
    isVerified(product) {
      if (!product.verified_at || !product.verification_period) {
        return false; // Not verified if no verification date or period
      }
      const verifiedDate = this.$moment.unix(product.verified_at);
      const verificationPeriodMonths = parseInt(product.verification_period, 10) || 0;
      const expiryDate = verifiedDate.add(verificationPeriodMonths, "months");
      const currentDate = this.$moment();

      return currentDate.isBefore(expiryDate); // True if current date is before expiry
    },

    openCreateProductPanel() {
      this.createProductPanelOpen = true;
    },
    closeCreateProductPanel() {
      this.createProductPanelOpen = false;
    },
  },
};
</script>

<style scoped>
.total-amount {
  margin-top: 10px;
  font-weight: bold;
}

::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}

::v-deep .highlight-row {
  background-color: rgba(0,0,0,.15) !important;
}


</style>
